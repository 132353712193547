<h2 class="cbms-h2">SMART Alert Templates</h2>
<div class="table-wrapper">
    <p-table
        *ngIf="alertTemplates$ | async as dataSource; else loading"
        class="cbms-generic-table-style"
        [value]="dataSource"
        [columns]="cols"
        [rows]="50"
        [paginator]="true"
        responsiveLayout="scroll"
        [scrollable]="true"
        scrollHeight="flex"
        [rowHover]="true"
        dataKey="id"
    >
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-end">
                <div>
                    <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                            (click)="addTemplate()"
                            [disabled]="false">
                        Add Template
                    </button>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="flex: 0 0 3rem !important; min-width: 3rem">
                    <div class="cbms-disply-flex-row-centered">
                        <span>Actions</span>
                    </div>
                </th>

                <th *ngFor="let col of columns"
                    [pSortableColumn]="col.field"
                    pResizableColumn
                    style="flex-basis: {{ col.width }}; min-width: {{col.width}}">
                    <div class="cbms-disply-flex-row-centered">
                        <span>{{ col.header }}</span>
                        <p-sortIcon
                            [field]="col.field"
                            ariaLabel="Activate to sort"
                            ariaLabelDesc="Activate to sort in descending order"
                            ariaLabelAsc="Activate to sort in ascending order"
                        ></p-sortIcon>
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-template let-columns="columns">
            <tr>
                <td style="flex: 0 0 3rem !important; min-width: 3rem">
                    <button
                        pButton
                        class="p-button-raised p-button-text cbms-row-button"
                        pTooltip="Delete alert template"
                        showDelay="1000"
                        hideDelay="500"
                        (click)="deleteMenu.show($event)"
                        disabled
                    >
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>

                    <button
                        pButton
                        type="button"
                        icon="pi pi-pencil"
                        class="ui-button-info cbms-margin-left"
                        (click)="editTemplate(template)"
                    ></button>

                    <p-overlayPanel #deleteMenu appendTo="body">
                        <p>
                            Please confirm you want to continue with the removal of this SMART Alert template
                        </p>
                        <hr>
                        <div class="delete-action-buttons">
                            <button pButton class="p-button-raised p-button-text cbms-button cbms-button__turquoise-bg"
                                    label="Confirm"
                                    (click)="deleteSmartAlertTemplate(template); deleteMenu.hide();"></button>
                            <button pButton class="p-button-raised p-button-text cbms-button"
                                    (click)="deleteMenu.hide()">Cancel
                            </button>
                        </div>
                    </p-overlayPanel>
                </td>
                <td
                    *ngFor="let col of columns"
                    style="flex-basis: {{ col.width }}; min-width: {{col.width}}">
                    <ng-container [ngSwitch]="col.field">
                        <ng-container *ngSwitchCase="'isEnabled'">
                            <p-inputSwitch [(ngModel)]="template[col.field]"
                                           (onChange)="changeAlertTemplateStatus($event, template)" disabled></p-inputSwitch>
                        </ng-container>

                        <ng-container *ngSwitchDefault>{{ template[col.field] }}</ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<ng-template #loading>
    <div class="cbms-spinner-wrapper">
        <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
    </div>
</ng-template>
