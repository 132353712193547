import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Site, SiteTag } from '../../models/site';
import { UtilsService } from '../../services/utils/util.service';
import { NotificationService } from '../../services/notification/notification.service';
import { BackendService } from '../../services/backend/backend.service';
import { v4 } from 'uuid';
import { DataService } from "../../services/data/data.service";

import _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
	selector: 'cbms-sites-tag-management',
	templateUrl: './sites-tag-management.component.html',
	styleUrls: ['./sites-tag-management.component.scss']
})
export class SitesTagManagementComponent implements OnInit {
	public sites: Site[] = [];
	public siteTagsHistoryData = [];
	public systemTags: SiteTag[] = [];
	public tagsToAdd: SiteTag[] = [];
	public existingTags: SiteTag[] = [];
	public tagsToRemove: SiteTag[] = [];
	public newTag: string = '';
	public isLoading: boolean = false;
	public tableName: string = 'siteTagMng';
	public cols = [
		{ field: 'customerName', header: 'Customer Name' },
		{ field: 'name', header: 'Site' },
		{ field: 'tags', header: 'Tags' }
	];

	private getSitesTagsSubscription: Subscription;

	constructor(public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
		private utilsService: UtilsService,
		private backendService: BackendService,
		private notificationService: NotificationService,
		private dataService: DataService) {

	}

	ngOnInit() {
		this.sites = _.cloneDeep(this.config.data);

		this.getExistingTags();
		this.siteTagsHistoryData.push(this.getTagsFromSite(this.sites));

		this.getSitesTagsSubscription = this.backendService.getSitesTags().subscribe(tags => {
		  this.systemTags = this.utilsService.orderListByFieldName(tags, 'name');
		});
	}

	public getExistingTags() {
		this.sites.forEach(site => {
			if (!site.tags) {
				return;
			}

			site.tags.forEach(tag => {
				if (tag.isDeleted) {
					return;
				}
				if (this.existingTags.findIndex(existingTag => existingTag.name === tag.name) === -1) {
					this.existingTags.push(tag);
				}
			});
		});
		this.existingTags = this.utilsService.orderListByFieldName(this.existingTags, 'name');
	}

	public addTags() {
		if (!this.tagsToAdd.length && !this.newTag) {
			return;
		}

		if (this.newTag && this.newTag.length >= 3) {
			this.tagsToAdd.push({ name: this.newTag });
		}
		let tagIndex = null;

		this.sites.forEach(site => {
			this.tagsToAdd.forEach(tagToAdd => {
				tagIndex = site.tags.findIndex(existingTag => tagToAdd.name === existingTag.name);
				if (tagIndex === -1) {
					tagToAdd.isNew = true;
					tagToAdd.id = tagToAdd.id || v4();
					if (!this.systemTags.map(tag => tag.id).includes(tagToAdd.id)) {
                        tagToAdd.shouldRemoveId = true;
                    }
					site.tags.push(tagToAdd);
				};
				if (_.has(site.tags[tagIndex], 'isDeleted')) {
					delete site.tags[tagIndex].isDeleted;
				}
			});
		});
		this.siteTagsHistoryData.push(this.getTagsFromSite(this.sites));
		this.tagsToAdd = [];
		this.newTag = '';
		this.existingTags = [];
		this.getExistingTags();
	}

	public removeTags() {
		if (!this.tagsToRemove.length) {
			return;
		}
		let tagIndex = null;
		this.sites.forEach(site => {
			this.tagsToRemove.forEach(tagToRemove => {
				tagIndex = site.tags.findIndex(existingTag => tagToRemove.name === existingTag.name);
				if (tagIndex !== -1) {
					if (site.tags[tagIndex].isNew) {
						site.tags.splice(tagIndex, 1);
					} else {
						site.tags[tagIndex].isDeleted = true;
					}
				};
			});
		});
		this.siteTagsHistoryData.push(this.getTagsFromSite(this.sites));
		this.tagsToRemove = [];
		this.existingTags = [];
		this.getExistingTags();
	}

	public undo() {
		let prevTags = [];
		if (this.siteTagsHistoryData.length === 1) {
			prevTags = this.siteTagsHistoryData[0];
		} else {
			this.siteTagsHistoryData.pop();
			prevTags = this.siteTagsHistoryData[this.siteTagsHistoryData.length - 1];
		}

		this.sites.forEach(site => {
			site.tags = [...prevTags[site.id].map(tag => _.cloneDeep(tag))];
		})
		this.tagsToAdd = [];
		this.tagsToRemove = [];
		this.existingTags = [];
		this.getExistingTags();
	}

	public close() {
		if (this.getSitesTagsSubscription) {
            this.getSitesTagsSubscription.unsubscribe();
        }
		this.ref.close();
	}

	public save() {
		this.isLoading = true;

		this.backendService.updateSitesTags(this.sites).subscribe(() => {
		  this.notificationService.addSuccessMessage('Edit Tags', 'Tags successfully updated.', false);
		  this.ref.close(true);
		  this.dataService.changeNewTagsStatus({sitesTagsUpdated: true});
		  this.isLoading = false;
		}, (error) => {
		  this.notificationService.addErrorMessage('Edit Tags', error);
		  this.isLoading = false;
		});
	}

	public getDataPointIds() {
		return this.sites.map(site => site.id);
	}

	public onColResize() {
		let columns = document.getElementsByClassName('ui-sortable-column');
		Array.from(columns).forEach((column: HTMLElement) => {
			sessionStorage.setItem(`${this.tableName}-${column.innerText.trim()}`, `${column.clientWidth}px`);
		})
	}

	public noChangesOnTags(): boolean {
		return _.isEqual(this.siteTagsHistoryData[0], this.siteTagsHistoryData[this.siteTagsHistoryData.length - 1]);
	}

	private getTagsFromSite(sites: Site[]) {
		let tags = {};
		sites.forEach(site => {
			if (!site.tags) {
				return;
			}

			tags[site.id] = [...site.tags.map(tag => _.cloneDeep(tag))];
		});
		return tags;
	}
}

