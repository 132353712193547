import { Component, OnInit } from '@angular/core';
import {
    AsyncPipe,
    NgForOf,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
} from '@angular/common';
import { ButtonDirective } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PrimeTemplate } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, of } from 'rxjs';
import { DataService } from '../../../services/data/data.service';
import { FilterService } from '../../../components/filter/filter.service';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

//just temp, for faking the data on FE, replace with BE model when available
interface AlertTemplate {
    id: string;
    name: string;
    type: 'Energy' | 'Maintenance';
    isEnabled: boolean;
}

@Component({
    selector: 'cbms-smart-alert-templates',
    standalone: true,
    imports: [
        AsyncPipe,
        ButtonDirective,
        DropdownModule,
        InputSwitchModule,
        MatIcon,
        MatProgressSpinner,
        NgForOf,
        NgIf,
        NgSwitchCase,
        OverlayPanelModule,
        PrimeTemplate,
        TableModule,
        TooltipModule,
        FormsModule,
        NgSwitch,
        NgSwitchDefault,
    ],
    templateUrl: './smart-alert-templates.component.html',
    styleUrl: './smart-alert-templates.component.scss',
})
export class SmartAlertTemplatesComponent implements OnInit {
    public cols: any[] = [
        { field: 'name', header: 'Template Name', position: 2, width: '100px' },
        { field: 'type', header: 'Template Type', position: 3, width: '100px' },
        {
            field: 'isEnabled',
            header: 'Activation Status',
            position: 4,
            width: '150px',
        },
    ];
    public alertTemplates$: Observable<AlertTemplate[]>;

    private customerId: string;

    constructor(
        private dataService: DataService,
        private filterService: FilterService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.dataService.currentTabHasChanged('smartAlertTemplates');

        this.getAlertTemplates();

        this.filterService.customerFilterChanges$.subscribe((data) => {
            this.customerId = data.selectedCustomer.id;
            this.getAlertTemplates();
        });
    }

    addTemplate(): void {
        this.router.navigate(['add-smart-alert'], {relativeTo: this.activatedRoute});
    }

    editTemplate(template: AlertTemplate): void {
        this.router.navigate(['edit-smart-alert'], {relativeTo: this.activatedRoute});
    }

    deleteSmartAlertTemplate(template: AlertTemplate): void {}

    changeAlertTemplateStatus(event: any, template: AlertTemplate): void {}

    private getAlertTemplates(): void {
        //todo replace with alert templates call after BE implementation
        this.alertTemplates$ = of([
            {
                id: 'ALERT001',
                name: 'High Energy Usage',
                type: 'Energy',
                isEnabled: true,
            },
            {
                id: 'ALERT002',
                name: 'Routine Maintenance Due',
                type: 'Maintenance',
                isEnabled: false,
            },
            {
                id: 'ALERT003',
                name: 'Overload Warning',
                type: 'Energy',
                isEnabled: true,
            },
        ]);
    }
}
