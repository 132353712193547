import { Component, Input, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { BINARY } from 'src/app/config/constants';
import { Option, Rule } from 'src/app/models/alert-rule.model';
import { DataPoint } from 'src/app/models/data-point';
import { Site } from 'src/app/models/site';

@Component({
    selector: 'cbms-query-builder',
    templateUrl: './query-builder.component.html',
    styleUrls: ['./query-builder.component.scss'],
})
export class QueryBuilderComponent {
    @Input() isReadOnly: boolean = false;
    @Input() selectedSite: Site;
    @Input() dataPointList: DataPoint[];
    @Input() templateVariableList: Option[];
    @Input() parentValue: any;
    @Input() query: { condition: string, rules: Rule[] } = { condition: 'and', rules: [] };
    operatorListForNonBinaryType: SelectItem[] = [
        { label: '>=', value: '>=' },
        { label: '>', value: '>' },
        { label: '<', value: '<' },
        { label: '<=', value: '<=' }
    ];
    operatorForBinaryType: SelectItem[] = [
        { label: '=', value: '=' }
    ];

    alertName = '';
    selectedDataPoint: DataPoint;

	binaryPointList: SelectItem[] = [
		{ label: 'True', value: "true"},
		{ label: 'False', value: "false"},
	];

	isFreeText = true;

    emptyRule: Rule = {
        condition: null,
        rules: null,
        field: 'datapoint.lastRecordedValue',
        value: '',
        entityName: '',
		isFreeText: true,
		isBinaryDatapointSignalType: false
    };

    constructor() {}

    onDataPointSelected(event: any, rule: Rule) {
        const selectedOption = event.value;
        rule.entityName = selectedOption?.name;

        if (selectedOption?.signalType === BINARY) {
            rule.isFreeText = false;
            rule.isBinaryDatapointSignalType = true;
            rule.operator = this.operatorForBinaryType[0].value; // set default operator
        } else {
            rule.isFreeText = true;
            rule.isBinaryDatapointSignalType = false;
            rule.operator = this.operatorListForNonBinaryType[0].value; // set default operator
            rule.value = '';
        }
        rule.entityId = selectedOption?.id;
    }

    /**
     * @param event, rule
     * @return void
     *
     */
    onTemplateVariableSelected(event: any, rule: Rule) {
        const selectedOption = event.value;
        if (selectedOption?.toLowerCase().includes(BINARY.toLowerCase())) {
            rule.isFreeText = false;
            rule.isBinaryDatapointSignalType = true;
            rule.operator = this.operatorForBinaryType[0].value; // set default operator
        } else {
            rule.isFreeText = true;
            rule.isBinaryDatapointSignalType = false;
            rule.operator = this.operatorListForNonBinaryType[0].value; // set default operator
            rule.value = '';
        }
        rule.templateVariable = selectedOption
    }

    addRule() {
        this.query.rules.push({...this.emptyRule});
    }

    addRuleset() {
        this.query.rules.push({ condition: 'or', rules: [], field: null, value: null, isBinaryDatapointSignalType: true});
    }

    removeRule(rule: any): void {
        this.query.rules = this.query.rules.filter((r) => r !== rule);
    }

    removeRuleSet(ruleset?: any, parent?: any): void {
        ruleset = ruleset || this.query;
        parent = parent || this.parentValue;

        parent.rules = parent.rules.filter((r) => r !== ruleset);
    }

    changeCondition(value: string): void {
        this.query.condition = value;
    }

    getDataPointLabel(dataPointId: string): string {
        if (!dataPointId) return '';
        let searchedDataPoint = this.dataPointList.find((dataPoint: DataPoint) => dataPoint?.id === dataPointId);
        if (!searchedDataPoint) return 'Data Point does not exist';
        return `${searchedDataPoint?.customName || searchedDataPoint?.description || searchedDataPoint?.objectName} (${searchedDataPoint?.signalType})`
    }
}
