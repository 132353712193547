import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';

import { Gateway } from '../../models/gateway';
import { Site } from '../../models/site';
import { Device, ViewDevice } from '../../models/device';
import { HttpBaseService } from '../http/http-base.service';
import {
    DataPoint,
    DataPointTag,
    IDataPointProperty,
    IUpdateDataPointPropertiesRes,
    ScheduleRequest,
    ScheduleRequestByTemplateId,
    ScheduleTemplate,
} from '../../models/data-point';
import { TelemetryData } from '../../models/telemetry-data';
import { Setpoint } from 'src/app/dialogs/setpoint/interfaces/setpoint.interface';
import { UtilsService } from '../../services/utils/util.service';
import {
    ExternalSource,
    NO_OF_TAGS_TO_FETCH,
    PAGE_SIZE_1000,
    PAGE_SIZE_10k,
    PAGE_SIZE_100k,
    PAGINATION,
    FieldList,
} from 'src/app/config/constants';
import { ScanStatusResponse } from '../../models/scan-status-response';
import { IdentifyDataPointsResponse } from '../../models/identify-datapoints-response';
import {
    FieldFilter,
    FilterParams,
} from '../../components/filter/interfaces/filter-params.interface';
import { SetpointResult } from '../../dialogs/setpoint/interfaces/setpointResult.interface';
import { map } from 'rxjs/operators';
import {
    OcSiteUpdateRequest,
    SiteCreateRequest,
} from '../../models/api/request';
import { IdNamePair, SiteResponse } from '../../models/api/response';
import { LazyLoadEvent } from 'primeng/api';
import {
    auditTrailMapper,
    AuditTrailResponse,
    IActionLogFileReq,
    IExportFileRes,
    IFilter,
} from '../../models/api/AuditTrail';
import { Customer } from 'src/app/models/customer';
import moment from 'moment';
import { GatewayAPI, toCoreModelWithPaginator } from '../../models/api/gateway';
import { DeviceAPI } from '../../models/api/device';
import {
    DataPointAPI,
    dataPointApiSortFieldMapper,
} from '../../models/api/data-point';
import { OCCustomer, OcSite } from '../../models/core/CoreModels';
import { UserFiltering, User } from 'src/app/models/user';
import { GatewayPaginator } from '../../models/core/gatewayPaginator';
import { AlertData, PointSelector, SmartAlert, SmartAlertTemplateReq } from 'src/app/models/alert-rule.model';
import { ApiAlertData } from '../../models/api/alert-rule.model';
import {
    ColumnsToFilter,
    SortOptions,
} from 'src/app/models/columns-filter.interface';

export enum SortOrderType {
    ASC = 'asc',
    DESC = 'desc',
}

// TODO: Split if growing too much
const IN = 'IN';

export const BE_WEEKLY_SCHEDULE = 'WEEKLY_SCHEDULE';
export const BE_EXCEPTION_SCHEDULE = 'EXCEPTION_SCHEDULE';
export const GATEWAY_RESPONSE_WEEKLY_SCHEDULE_TYPE = 'weekly_schedule';
export const GATEWAY_RESPONSE_EXCEPTION_SCHEDULE_TYPE = 'exception_schedule';

export enum UpdateType {
    COPY_SCHEDULE = 'COPY_SCHEDULE',
    COPY_SCHEDULE_TEMPLATE = 'COPY_SCHEDULE_TEMPLATE',
}

const IS = 'IS';

//endpoints
const SITES_ENDPOINT = 'sites';
const GATEWAYS_ENDPOINT = 'gateways';
const CUSTOMERS_ENDPOINT = 'customers';
const DATAPOINT_ENDPOINTS = `data-points`;

const ASCENDING_ORDER = 1;
export let getSortOrder = (sortOrder: number) =>
    sortOrder === ASCENDING_ORDER ? SortOrderType.ASC : SortOrderType.DESC;

const AUDIT_TRAILS_ENDPOINT = 'audit-trails';

const CONTAINS = 'CONTAINS';
export const VIRTUAL_ADDRESS = 'virtual_address';
export const VIRTUAL = 'virtual';
export const DNS_CSV = 'dns_csv';

@Injectable()
export class BackendService {
    constructor(
        private httpBaseService: HttpBaseService,
        private utilsService: UtilsService
    ) {}

    public getGateways(
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = PAGE_SIZE_10k,
        gatewayFilter: Gateway[] = []
    ): Observable<GatewayPaginator> {
        let body = this.getRequestBodyWithBasicFilter();
        if (gatewayFilter.length) {
            body.filter.boolean.expressions.push(
                this.getRelation(
                    'id',
                    'IN',
                    gatewayFilter.map((item) => item.id)
                )
            );
        }

        let path = this.getPageablePath(
            GATEWAYS_ENDPOINT,
            pageNumber,
            pageSize
        );

        return this.httpBaseService
            .post<{
                content: GatewayAPI[];
                totalElements: number;
            }>(path, null, body)
            .pipe(map((value) => toCoreModelWithPaginator(value)));
    }

    public getGatewaysSummary(
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = PAGE_SIZE_10k,
        gatewayFilter: Gateway[] = []
    ): Observable<GatewayPaginator> {
        let body = this.getRequestBodyWithBasicFilter();
        if (gatewayFilter.length) {
            body.filter.boolean.expressions.push(
                this.getRelation(
                    'id',
                    'IN',
                    gatewayFilter.map((item) => item.id)
                )
            );
        }

        let path = this.getSummaryPageablePath(
            GATEWAYS_ENDPOINT,
            pageNumber,
            pageSize
        );

        return this.httpBaseService
            .post<{
                content: GatewayAPI[];
                totalElements: number;
            }>(path, null, body)
            .pipe(map((value) => toCoreModelWithPaginator(value)));
    }

    public addGateway(gateway: Gateway): Observable<any> {
        return this.httpBaseService.post<Gateway>(
            `gateways`,
            null,
            <GatewayAPI>(<unknown>gateway)
        );
    }

    public exportExportUserDataFileBySystemAdmin(
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = this.utilsService.getDataPointsPageSize(),
        filters: UserFiltering,
        customerId: string,
        sortOptions?: SortOptions
    ): Observable<any> {
        const apiAddress = `users-with-permission/export?`;
        let path = this.generateUserManagementQueryParams(
            apiAddress,
            pageNumber,
            pageSize,
            filters,
            sortOptions,
            customerId
        );
        return this.httpBaseService.getFileWithHeader(path);
    }

    /**
     * @description customer admin export file
     * @input none
     * @output Observable<HttpResponse<Blob>>
     */
    public exportUserDataFileByCustomerAdmin(
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = this.utilsService.getDataPointsPageSize(),
        filters: UserFiltering,
        sortOptions?: SortOptions
    ): Observable<any> {
        const apiAddress = `users-with-permission/export?`;
        let path = this.generateUserManagementQueryParams(
            apiAddress,
            pageNumber,
            pageSize,
            filters,
            sortOptions
        );
        return this.httpBaseService.getFileWithHeader(path);
    }

    public updateGateway(gateway: Gateway): Observable<any> {
        return this.httpBaseService.patch<Gateway>(
            `gateways`,
            null,
            <GatewayAPI>(<unknown>gateway)
        );
    }

    public getGatewayConnectionStatus(gateway: Gateway) {
        return this.httpBaseService.get(`connections/${gateway.id}`);
    }

    public openGatewayConnection(gateway: Gateway) {
        return this.httpBaseService.post(`connections/${gateway.id}`);
    }

    public closeGatewayConnection(gateway: Gateway) {
        return this.httpBaseService.delete(`connections/${gateway.id}`);
    }

    public getCustomers(): Observable<Customer[]> {
        return this.httpBaseService
            .post<any>(`customers/filter`)
            .pipe(map((response) => response.content));
    }

    public getOcTenants(): Observable<IdNamePair[]> {
        return this.httpBaseService.get<any>(`optimum-commercial/tenants`);
    }

    public getPointSelectors(): Observable<PointSelector[]> {
        return this.httpBaseService.get<any>(`alert-template/point-selectors`);
    }

    public getOcClassifications(): Observable<any[]> {
        return this.httpBaseService.get<any>(
            `optimum-commercial/classifications`
        );
    }

    public getOcChannels(classification: string): Observable<any[]> {
        return this.httpBaseService.get<any>(
            `optimum-commercial/classifications/${classification}/channels`
        );
    }

    public getOcCustomers(tenantId): Observable<OCCustomer[]> {
        return this.httpBaseService.get<any>(
            `optimum-commercial/tenants/${tenantId}/customers`
        );
    }

    public scanForDevices(gatewayId: string): Observable<Device[]> {
        const params = new HttpParams().set('secondsToWait', '10');
        const observable = this.httpBaseService.put<any>(
            `gateways/${gatewayId}/scan`,
            params
        );
        return observable.pipe(map((value) => this.toDeviceCoreModel(value)));
    }

    public addDevice(device: Device): Observable<Device> {
        return this.httpBaseService.post<Device>(`devices`, null, device);
    }

    public getVisibleDevicesBySite(
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = PAGINATION.SIZE,
        deviceFilter: ViewDevice[] = [],
        gatewayFilter: Gateway[] = []
    ): Observable<Device[]> {
        let body = this.getRequestBodyWithBasicFilter();
        body['overrideOnlineStatus'] = true;

        body.filter.boolean.expressions.push({
            relation: {
                field: 'hidden',
                operator: IS,
                value: false,
            },
        });

        if (deviceFilter.length) {
            body.filter.boolean.expressions.push({
                relation: {
                    field: 'id',
                    value: deviceFilter.map((device) => device.id),
                    operator: IN,
                },
            });
        }
        if (gatewayFilter.length) {
            body.filter.boolean.expressions.push({
                relation: {
                    field: 'gateway.id',
                    value: gatewayFilter.map((item) => item.id),
                    operator: IN,
                },
            });
        }

        const path = this.getSortablePath(
            `devices/filter?page=${pageNumber}&size=${pageSize}`,
            {
                sortField: 'name',
                sortOrder: SortOrderType.ASC,
            }
        );
        return this.httpBaseService
            .post<any>(path, null, body)
            .pipe(
                map((body) =>
                    Object.assign(
                        {},
                        { ...body },
                        { content: this.toDeviceCoreModel(body.content) }
                    )
                )
            );
    }

    public getDevicesSummary(
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = PAGINATION.SIZE,
        deviceFilter: ViewDevice[] = [],
        gatewayFilter: Gateway[] = []
    ): Observable<Device[]> {
        let body = this.getRequestBodyWithBasicFilter();
        body.filter.boolean.expressions.push({
            relation: {
                field: 'hidden',
                operator: IS,
                value: false,
            },
        });

        if (deviceFilter.length) {
            body.filter.boolean.expressions.push({
                relation: {
                    field: 'id',
                    value: deviceFilter.map((device) => device.id),
                    operator: IN,
                },
            });
        }
        if (gatewayFilter.length) {
            body.filter.boolean.expressions.push({
                relation: {
                    field: 'gateway.id',
                    value: gatewayFilter.map((item) => item.id),
                    operator: IN,
                },
            });
        }

        const path = this.getSortablePath(
            `devices/filter-summary?page=${pageNumber}&size=${pageSize}`,
            {
                sortField: 'name',
                sortOrder: SortOrderType.ASC,
            }
        );
        return this.httpBaseService.post<any>(path, null, body);
    }

    public toDeviceCoreModel(gatewayResponses: DeviceAPI[]): Device[] {
        const devices = gatewayResponses.map(
            (deviceAPI) => <Device>(<unknown>deviceAPI)
        );

        let computeOptimimCCExtensions = function (device: Device) {
            //Optimum CC
            if (ExternalSource.BACKNET !== device.source) {
                return;
            }

            if (device.extensions.device_address) {
                device.deviceAddress = device.extensions.device_address.value;
            }
            if (device.extensions.device_id) {
                device.deviceId = device.extensions.device_id.value;
            }
            if (device.extensions.object_name) {
                device.objectName = device.extensions.object_name.value;
            }
            if (device.extensions.vendor_name) {
                device.vendorName = device.extensions.vendor_name.value;
            }
            if (device.extensions.model_name) {
                device.modelName = device.extensions.model_name.value;
            }
            if (device.extensions.system_status) {
                device.systemStatus = device.extensions.system_status.value;
            }
        };
        let computeWiseMeterExtensions = function (device: Device) {
            //Wisemeter
            if (ExternalSource.WISEMETER !== device.source) {
                return;
            }

            if (device.extensions.name) {
                device.objectName = device.extensions.name.value;
            }
            if (device.extensions.id) {
                device.deviceId = device.extensions.id.value;
            }
        };
        let computeFromExtensions = function (device: Device) {
            if (!device.extensions) {
                return;
            }
            computeOptimimCCExtensions(device);
            computeWiseMeterExtensions(device);
        };
        devices.forEach((device) => {
            computeFromExtensions(device);
        });
        return devices;
    }

    /**
     * @description get filtering data
     * @input columnsToFilter type ColumnsToFilter, timeframeFilter type Date[] (e.g: ['Thu Oct 31 2024 00:00:00 GMT+0700 (Indochina Time)', 'Thu Nov 07 2024 23:59:00 GMT+0700 (Indochina Time)'])
     * @output:
     */
    generateFilterObject(
        columnsToFilter: ColumnsToFilter,
        timeframeFilter: Date[]
    ): { filter: IFilter } {
        let columnsFilterExpressions = [];
        if (columnsToFilter) {
            columnsFilterExpressions = Object.keys(columnsToFilter).map((key) =>
                this.getRelation(
                    columnsToFilter[key].column,
                    columnsToFilter[key].operator,
                    columnsToFilter[key].value
                )
            );
        }

        let timeframeFilterExpression = [];
        let endDate: Date;

        if (timeframeFilter) {
            if (timeframeFilter[0]) {
                timeframeFilterExpression.push(
                    this.getRelation(
                        'actionTimestamp',
                        'GTE',
                        timeframeFilter[0]
                    )
                );
            }

            endDate = timeframeFilter[1]
                ? moment(timeframeFilter[1])
                      .endOf('day')
                      .seconds(0)
                      .milliseconds(0)
                      .toDate()
                : moment(timeframeFilter[0])
                      .endOf('day')
                      .seconds(0)
                      .milliseconds(0)
                      .toDate();

            timeframeFilterExpression.push(
                this.getRelation('actionTimestamp', 'LTE', endDate)
            );
        }

        let body = this.getRequestBodyWithBasicFilter();
        body.filter.boolean.expressions.push(...timeframeFilterExpression);
        body.filter.boolean.expressions.push(...columnsFilterExpressions);

        return body;
    }

    private getRequestBodyWithBasicFilter(operator = 'AND') {
        let body = {
            filter: {
                boolean: {
                    operator: operator,
                    expressions: [],
                },
            },
        };
        return body;
    }

    public getDevicesByGateway(gatewayIdCSV?: string): Observable<Device[]> {
        let body = this.getRequestBodyWithBasicFilter();
        body['overrideOnlineStatus'] = true;

        if (gatewayIdCSV) {
            body.filter.boolean.expressions.push(
                this.getRelation('gateway.id', IN, gatewayIdCSV.split(','))
            );
        }
        return this.httpBaseService
            .post<any>(
                `devices/filter?page=0&size=${PAGE_SIZE_10k}`,
                null,
                body
            )
            .pipe(
                map((body) => body.content),
                map((result) => this.toDeviceCoreModel(result))
            );
    }

    public generateOverridesReport(customerId: string): Observable<any> {
        return this.httpBaseService.post<Gateway>(
            `export/start-csv-datapoints-overrides-report-by-customer/${customerId || this.getSelectedCustomer().id}`,
            null
        );
    }

    public getOverridesReportStatus(customerId: string): Observable<any> {
        return this.httpBaseService.get(
            `export/csv-datapoints-overrides-report-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public exportOverridesReport(customerId: string): Observable<any> {
        return this.httpBaseService.getFile(
            `export/download-csv-datapoints-overrides-report-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public generateSiteConnectivityReport(customerId: string): Observable<any> {
        return this.httpBaseService.post<Gateway>(
            `export/start-csv-site-connectivity-report-by-customer/${customerId || this.getSelectedCustomer().id}`,
            null
        );
    }

    public runDatapointIntegrityReport(customerId: string): Observable<any> {
        return this.httpBaseService.post<Gateway>(
            `export/start-csv-datapoint-integrity-report-by-customer/${customerId || this.getSelectedCustomer().id}`,
            null
        );
    }

    public runDatapointFaultyReport(
        customerId: string,
        payload: any
    ): Observable<any> {
        return this.httpBaseService.post<Gateway>(
            `export/start-csv-datapoint-faulty-report-by-customer/${customerId || this.getSelectedCustomer().id}`,
            null,
            payload
        );
    }

    public getDatapointFaultyReportStatus(customerId: string): Observable<any> {
        return this.httpBaseService.get(
            `export/csv-site-datapoint-faulty-report-status-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public getDatapointIntegrityReportStatus(
        customerId: string
    ): Observable<any> {
        return this.httpBaseService.get(
            `export/csv-site-datapoint-integrity-report-status-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public getSiteConnectivityReportStatus(
        customerId: string
    ): Observable<any> {
        return this.httpBaseService.get(
            `export/csv-site-connectivity-report-status-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public getTwinDatapointReportStatus(customerId: string): Observable<any> {
        return this.httpBaseService.get(
            `api/v1/customer-auto-optimisation/configs/csv-site-datapoint-auto-optimisation-twin-datapoint-report-status-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public getTwinDatapointReportCsv(customerId: string): Observable<any> {
        return this.httpBaseService.getFile(
            `api/v1/customer-auto-optimisation/configs/download-csv-datapoints-auto-optimisation-twin-datapoint-report-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public getTwinScheduleReportStatus(customerId: string): Observable<any> {
        return this.httpBaseService.get(
            `api/v1/customer-auto-optimisation/configs/csv-site-datapoint-auto-optimisation-twin-schedule-report-status-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public getTwinScheduleReportCsv(customerId: string): Observable<any> {
        return this.httpBaseService.getFile(
            `api/v1/customer-auto-optimisation/configs/download-csv-datapoints-auto-optimisation-twin-schedule-report-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }
    public exportSiteConnectivityReport(customerId: string): Observable<any> {
        return this.httpBaseService.getFile(
            `export/download-csv-site-connectivity-report-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public generateDeviceConnectivityReport(
        customerId: string
    ): Observable<any> {
        return this.httpBaseService.post<Gateway>(
            `export/start-csv-device-connectivity-report-by-customer/${customerId || this.getSelectedCustomer().id}`,
            null
        );
    }

    public getDeviceConnectivityReportStatus(
        customerId: string
    ): Observable<any> {
        return this.httpBaseService.get(
            `export/csv-device-connectivity-report-status-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public exportDeviceConnectivityReport(customerId: string): Observable<any> {
        return this.httpBaseService.getFile(
            `export/download-csv-device-connectivity-report-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public exportDatapointIntegrityReport(customerId: string): Observable<any> {
        return this.httpBaseService.getFile(
            `export/download-csv-datapoint-integrity-report-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public exportDatapointFaultyReport(customerId: string): Observable<any> {
        return this.httpBaseService.getFile(
            `export/download-csv-datapoint-faulty-report-by-customer/${customerId || this.getSelectedCustomer().id}`
        );
    }

    public scanForDataPoints(
        deviceId: string
    ): Observable<IdentifyDataPointsResponse> {
        return this.httpBaseService.post(`${DATAPOINT_ENDPOINTS}`, null, {
            deviceId: deviceId,
        });
    }

    public getDataPointScanStatusBySessionId(
        sessionId?: string
    ): Observable<ScanStatusResponse> {
        const params = new HttpParams().set('sessionId', sessionId);
        return this.httpBaseService.get<ScanStatusResponse>(
            `${DATAPOINT_ENDPOINTS}/scan-status`,
            params
        );
    }

    public getDataPointProperties(dataPointId: string): Observable<any> {
        return this.httpBaseService.get(
            `${DATAPOINT_ENDPOINTS}/${dataPointId}/properties`
        );
    }

    public getDataPointSchedule(dataPointId: string): Observable<any> {
        return this.httpBaseService.get(
            `${DATAPOINT_ENDPOINTS}/${dataPointId}/schedules`
        );
    }

    public getDataPointWeeklySchedule(dataPointId: string): Observable<any> {
        return this.httpBaseService.get(
            `${DATAPOINT_ENDPOINTS}/${dataPointId}/weekly-schedule`
        );
    }

    public getDataPointExceptionSchedule(dataPointId: string): Observable<any> {
        return this.httpBaseService.get(
            `${DATAPOINT_ENDPOINTS}/${dataPointId}/exception-schedule`
        );
    }

    public updateDataPointsSchedule(
        scheduleRequest: ScheduleRequest | ScheduleRequestByTemplateId
    ): Observable<SetpointResult[]> {
        return this.httpBaseService.patch(
            `${DATAPOINT_ENDPOINTS}/schedules`,
            null,
            scheduleRequest
        );
    }

    public saveScheduleAsTemplate(template: ScheduleTemplate) {
        return this.httpBaseService.post<Gateway>(`schedule-templates`, null, {
            ...template,
            customerId: this.getSelectedCustomer().id,
        });
    }

    public saveSite(request: SiteCreateRequest) {
        if (request.id) {
            return this.httpBaseService.patch<SiteResponse>(
                `sites`,
                null,
                request
            );
        } else {
            return this.httpBaseService.post<SiteResponse>(
                `sites`,
                null,
                request
            );
        }
    }

    public manageDashboardsVisibility(
        dashboardList: { siteId: string; dashboardEnabled: boolean }[]
    ) {
        return this.httpBaseService.post(
            `sites/dashboard`,
            null,
            dashboardList
        );
    }

    public updateWeeklyScheduleTemplate(template: any) {
        const body = {
            name: template.name,
            id: template.id,
            weeklyScheduleObject: {
                scheduleDefault: template.scheduleDefault,
                weeklyScheduleWithInterval: template.weeklyScheduleCalendarData,
            },
        };
        return this.httpBaseService.patch<ScheduleTemplate>(
            'schedule-templates',
            null,
            body
        );
    }

    public updateExceptionScheduleTemplate(template: any) {
        return this.httpBaseService.patch<ScheduleTemplate>(
            'schedule-templates',
            null,
            template
        );
    }

    public updateDataPointTags(
        dataPointId: string,
        tags: DataPointTag[]
    ): Observable<any> {
        return this.httpBaseService.put(
            `${DATAPOINT_ENDPOINTS}/${dataPointId}/tags`,
            null,
            tags
        );
    }

    public updateDataPointsOcSite(
        dataPointIdList: string[],
        ocSite: OcSite
    ): Observable<any> {
        const ocSiteUpdateRequest: OcSiteUpdateRequest = {
            id: ocSite.id,
            name: ocSite.name,
            customer: <IdNamePair>ocSite.customer,
            tenant: <IdNamePair>ocSite.tenant,
            dataPointIdList: dataPointIdList,
            granularity: ocSite.granularity,
            isAccumulator: ocSite.isAccumulator,
            dataSource: ocSite.dataSource,
            classification: ocSite.classification,
            channel: ocSite.channel,
        };

        return this.httpBaseService.patch(
            `${DATAPOINT_ENDPOINTS}/oc-site`,
            null,
            ocSiteUpdateRequest
        );
    }

    public getScheduleTemplatesByType(
        scheduleType: string = BE_WEEKLY_SCHEDULE,
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = PAGINATION.SIZE,
        sortOptions?: SortOptions
    ) {
        let body = this.getRequestBodyWithBasicFilter();
        body.filter.boolean.expressions.push(
            this.getRelation('scheduleType', IS, scheduleType)
        );

        const selectedCustomer = this.getSelectedCustomer();
        if (selectedCustomer) {
            body.filter.boolean.expressions.push(
                this.getRelation('customer.id', IS, selectedCustomer.id)
            );
        }

        let path = `schedule-templates/filter?page=${pageNumber}&size=${pageSize}`;
        if (sortOptions && sortOptions.sortField) {
            path = `${path}&sort=${sortOptions.sortField},${sortOptions.sortOrder}`;
        }

        return this.httpBaseService.post<{ content: ScheduleTemplate[] }>(
            path,
            null,
            body
        );
    }

    public getScheduleTemplateByIdAndType(
        scheduleType: string = BE_WEEKLY_SCHEDULE,
        id: string
    ) {
        let pageNumber: number = PAGINATION.DEFAULT_PAGE_NR;
        let pageSize: number = PAGINATION.SIZE;

        let body = this.getRequestBodyWithBasicFilter();
        body.filter.boolean.expressions.push(
            this.getRelation('scheduleType', IS, scheduleType)
        );
        body.filter.boolean.expressions.push(this.getRelation('id', IS, id));

        let path = `schedule-templates/filter?page=${pageNumber}&size=${pageSize}`;

        return this.httpBaseService.post<{ content: ScheduleTemplate[] }>(
            path,
            null,
            body
        );
    }

    public createTwinFromDatapoint(dataPointIds: string[]): Observable<any> {
        return this.httpBaseService.put(
            `${DATAPOINT_ENDPOINTS}/twin-datapoint`,
            null,
            dataPointIds
        );
    }

    public createTwinFromSchedule(dataPointIds: string[]): Observable<any> {
        return this.httpBaseService.put(`twin-schedules`, null, dataPointIds);
    }

    public compareScheduleToTwin(dataPointIds: string[]): Observable<any> {
        return this.httpBaseService.post(
            `twin-schedules/compare-schedules`,
            null,
            dataPointIds
        );
    }

    public compareDataPointToTwin(dataPointIds: string[]): Observable<any> {
        return this.httpBaseService.post(
            `data-points/twin-compare`,
            null,
            dataPointIds
        );
    }

    public writeTwinToSchedule(dataPointIds: string[]): Observable<any> {
        return this.httpBaseService.post(
            `twin-schedules/copy-schedules`,
            null,
            dataPointIds
        );
    }

    public writeTwinToDatapoint(dataPointIds: string[]): Observable<any> {
        return this.httpBaseService.patch(
            `${DATAPOINT_ENDPOINTS}/twin-datapoint`,
            null,
            dataPointIds
        );
    }

    public getAllCustomersFromResponse() {
        let pageNumber: number = PAGINATION.DEFAULT_PAGE_NR;
        let pageSize: number = PAGINATION.SIZE;
        let sortOptions: SortOptions = {
            sortField: 'name',
            sortOrder: SortOrderType.ASC,
        };

        let path = this.getSortablePath(
            this.getPageablePath(CUSTOMERS_ENDPOINT, pageNumber, pageSize),
            sortOptions
        );

        return this.httpBaseService.post<{ content: IdNamePair[] }>(
            path,
            null,
            null
        );
    }

    public getDataPointTagsAutoSuggestions(
        value: string = ''
    ): Observable<DataPointTag[]> {
        let params = new HttpParams();
        params = params.set('name', value);

        return this.httpBaseService.get<DataPointTag[]>(
            `${DATAPOINT_ENDPOINTS}/tags?page=0&size=${NO_OF_TAGS_TO_FETCH}`,
            params
        );
    }

    public updateDataPointsTags(dataPoints: DataPoint[]): Observable<any> {
        const body: { [key: string]: DataPointTag[] } = {};

        dataPoints.forEach((dataPoint) => {
            dataPoint.tags.forEach((tag) => {
                if (tag.shouldRemoveId) {
                    delete tag.id;
                }
            });
            body[dataPoint.id] = dataPoint.tags.filter((tag) => !tag.isDeleted);
        });

        return this.httpBaseService.put(
            `${DATAPOINT_ENDPOINTS}/tags`,
            null,
            body
        );
    }

    public updateSitesTags(sites: Site[]): Observable<any> {
        const body: { [key: string]: DataPointTag[] } = {};

        sites.forEach((site) => {
            site.tags.forEach((tag) => {
                if (tag.shouldRemoveId) {
                    delete tag.id;
                }
            });
            body[site.id] = site.tags.filter((tag) => !tag.isDeleted);
        });

        return this.httpBaseService.put(`${SITES_ENDPOINT}/tags`, null, body);
    }

    // use this method after post is implemented on BE
    public getSitesTags(
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = PAGE_SIZE_1000,
        sortOptions?: SortOptions
    ) {
        let body = this.getRequestBodyWithBasicFilter();
        let path = `${SITES_ENDPOINT}/tags/filter?page=${pageNumber}&size=${pageSize}`;
        if (sortOptions) {
            path = this.getSortablePath(path, sortOptions);
        }

        const selectedCustomer = this.getSelectedCustomer();
        if (selectedCustomer) {
            body.filter.boolean.expressions.push(
                this.getRelation('customer.id', IS, selectedCustomer.id)
            );
        }

        return this.httpBaseService
            .post<any>(path, null, body)
            .pipe(map((response) => response.content));
    }

    public setPollingDataPoints(
        deviceId: string,
        idListToPoll: string[],
        idListToUnpoll: string[]
    ): Observable<void> {
        return this.httpBaseService.put(
            `devices/${deviceId}/delta-polling`,
            null,
            { idListToPoll, idListToUnpoll }
        );
    }

    public setDeviceHiddenFlag(
        deviceId: string,
        hidden: boolean
    ): Observable<void> {
        return this.httpBaseService.put(
            `devices/${deviceId}/hidden`,
            null,
            hidden
        );
    }

    public deleteGatewayById(gateway: Gateway): Observable<void> {
        const forceDelete = !gateway.online ? '?force=true' : '';
        return this.httpBaseService.delete<void>(
            `gateways/${gateway.id}${forceDelete}`
        );
    }

    public deleteGatewayByIdList(selectedGatewayIdList: string[]) {
        let body: string[] = selectedGatewayIdList;
        const forceDelete = '?force=true';
        return this.httpBaseService.delete<void>(
            `gateways${forceDelete}`,
            null,
            body
        );
    }

    public deleteSitesByIdList(selectedSiteIdList: string[]) {
        const forceDelete = '?force=true';
        return this.httpBaseService.delete<void>(
            `sites${forceDelete}`,
            null,
            selectedSiteIdList
        );
    }

    public deleteDeviceById(deviceId: string): Observable<void> {
        return this.httpBaseService.delete<void>(`devices/${deviceId}`);
    }

    public deleteDevicesByIdList(deviceIdList: string[]): Observable<void> {
        return this.httpBaseService.delete<void>(`devices`, null, deviceIdList);
    }

    public deleteSiteById(siteId: string): Observable<void> {
        return this.httpBaseService.delete<void>(`sites/${siteId}?force=true`);
    }

    public getTelemetryData(
        dataPoint: DataPoint,
        from: string,
        to: string
    ): Observable<TelemetryData> {
        const params = new HttpParams()
            .set('dataPointId', dataPoint.id)
            .set('from', from)
            .set('to', to);
        return this.httpBaseService.get<TelemetryData>(`time-series`, params);
    }

    public patchSetpoint(
        setpoints: Setpoint[],
        priority: string,
        clearPriority: boolean = false
    ): Observable<any> {
        const body: {
            setpointUpdates: {
                dataPointId: string;
                value: number;
                priority: string;
            }[];
        } = {
            setpointUpdates: setpoints.map((setpoint) => {
                let setpointToSend: any = {
                    dataPointId: setpoint.dataPointId,
                    value: <number>setpoint.newValue,
                    rollbackTimer: setpoint.rollbackTimer,
                    priority: this.computePriority(
                        clearPriority,
                        priority,
                        setpoint
                    ),
                };

                return setpointToSend;
            }),
        };
        return this.httpBaseService.patch(
            `${DATAPOINT_ENDPOINTS}/set-points`,
            null,
            body
        );
    }

    public writeCommand(pointToUpdate: any, priority: string): Observable<any> {
        const body = {
            setpointUpdates: [
                {
                    dataPointId: pointToUpdate.dataPointId,
                    value: pointToUpdate.value,
                    rollbackTimer: pointToUpdate.rollbackTimer,
                    priority: priority,
                },
            ],
        };
        return this.httpBaseService.patch(
            `${DATAPOINT_ENDPOINTS}/set-points`,
            null,
            body
        );
    }

    public resetSetpoint(setpoints: Setpoint[]): Observable<any> {
        const body: {
            setpointUpdates: {
                dataPointId: string;
                value: number;
                rollbackTimer: number;
                rundownTimer: boolean;
            }[];
        } = {
            setpointUpdates: setpoints.map((setpoint) => {
                let setpointToSend = {
                    dataPointId: setpoint.dataPointId,
                    value: <number>setpoint.newValue,
                    rollbackTimer: setpoint.rollbackTimer,
                    rundownTimer: true,
                };
                return setpointToSend;
            }),
        };
        return this.httpBaseService.patch(
            `${DATAPOINT_ENDPOINTS}/set-points-with-rollback`,
            null,
            body
        );
    }

    private computePriority(
        clearPriority: boolean,
        priority: string,
        setpoint: Setpoint
    ) {
        if (clearPriority) {
            return priority;
        }
        return setpoint.newValue === null ? null : priority;
    }

    public updateDataPointUserDescription(
        id: string,
        customName: string
    ): Observable<any> {
        const body: { id: string; customName: string } = {
            id,
            customName,
        };
        return this.httpBaseService.patch(`${DATAPOINT_ENDPOINTS}`, null, body);
    }

    public getDataPointsDetails(
        filterParams: FilterParams,
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = this.utilsService.getDataPointsPageSize(),
        withGlobalFilters: boolean = true,
        sortOptions?: SortOptions
    ): Observable<any> {
        //TODO fix BE payload, for datapoint.device node, in the end,
        // we must return  Observable<{ content: DataPoint[]; totalElements: number }, avoid return Observable<any>
        let path = `${DATAPOINT_ENDPOINTS}/filter?page=${pageNumber}&size=${pageSize}`;
        return this.getDataPoints(
            filterParams,
            path,
            withGlobalFilters,
            sortOptions
        );
    }

    public getDataPointsSummary(
        // used for Datapoint tab, for better performance
        filterParams: FilterParams,
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = this.utilsService.getDataPointsPageSize(),
        withGlobalFilters: boolean = true,
        sortOptions?: SortOptions
    ): Observable<{ content: DataPoint[]; totalElements: number }> {
        let path = `${DATAPOINT_ENDPOINTS}/filter-summary?page=${pageNumber}&size=${pageSize}`;
        return this.getDataPoints(
            filterParams,
            path,
            withGlobalFilters,
            sortOptions
        );
    }

    public getAllDataPointsIdList(filterParams: FilterParams) {
        const withGlobalFilters = true;
        let body = this.computeDatapointsFilters(
            filterParams,
            withGlobalFilters
        );
        return this.httpBaseService.post<string[]>(
            'data-points/id-list/filter',
            null,
            body
        );
    }

    private getDataPoints(
        filterParams: FilterParams,
        path: string,
        withGlobalFilters: boolean,
        sortOptions?: SortOptions
    ): Observable<{ content: DataPoint[]; totalElements: number }> {
        let body = this.computeDatapointsFilters(
            filterParams,
            withGlobalFilters
        );
        return this.httpBaseService
            .post<{
                content: DataPointAPI[];
                totalElements: number;
            }>(this.getSortablePath(path, sortOptions), null, body)
            .pipe(map((value) => this.mapResponseToDatapointCoreModel(value)));
    }

    public getDataPointsRollbackInfo(dataPointIds: string[]) {
        return this.httpBaseService.post(
            `data-points/processes`,
            null,
            dataPointIds
        );
    }

    public deleteDataPoints(selectedDataPointIdList: string[]) {
        return this.httpBaseService.delete<void>(
            `data-points`,
            null,
            selectedDataPointIdList
        );
    }

    public getLastCustomer(): Observable<any> {
        return this.httpBaseService.get<Customer>(`filters/last-customer`);
    }

    public updateLastCustomer(customerId: string) {
        return this.httpBaseService.patch(
            `filters/last-customer?customerId=${customerId}`,
            null,
            null
        );
    }

    public getUsersByCustomerAdmin(
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = this.utilsService.getDataPointsPageSize(),
        filters: UserFiltering,
        sortOptions?: SortOptions
    ): Observable<any> {
        const apiAddress = `users-with-permission?`;
        let path = this.generateUserManagementQueryParams(
            apiAddress,
            pageNumber,
            pageSize,
            filters,
            sortOptions
        );
        return this.httpBaseService.get<any>(path);
    }

    public getUsersBySystemAdmin(
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = this.utilsService.getDataPointsPageSize(),
        filters: UserFiltering,
        customerId: string,
        sortOptions: SortOptions
    ): Observable<any> {
        const apiAddress = `users-with-permission?`;
        let path = this.generateUserManagementQueryParams(
            apiAddress,
            pageNumber,
            pageSize,
            filters,
            sortOptions,
            customerId
        );
        return this.httpBaseService.get<any>(path);
    }

    /**
     * @description generate query parameters
     * @param apiAddress
     * @param pageNumber
     * @param pageSize
     * @param filters
     * @param sortOptions
     * @param customerId
     * @returns a path with query parameters
     */
    generateUserManagementQueryParams(
        apiAddress: string,
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = this.utilsService.getDataPointsPageSize(),
        filters: UserFiltering,
        sortOptions?: SortOptions,
        customerId?: string
    ): string {
        let path = `${apiAddress}page=${pageNumber}&size=${pageSize}`;
        if (customerId)
            path = path.concat(
                `&customerId=${customerId || this.getSelectedCustomer()?.id}`
            );
        path = this.generateFilterParameter(path, filters);
        path = this.getSortablePath(path, sortOptions);
        return path;
    }

    /**
     * @description generate query parameters
     * @param path
     * @param filters
     * @returns new path with filtering parameters
     */
    generateFilterParameter(path: string, filters: UserFiltering): string {
        Object.keys(filters).forEach((key) => {
            if (filters[key] !== '') {
                path = path + `&${key}=${filters[key]}`;
            }
        });
        return path;
    }

    public getIdentityProviderList(): Observable<any> {
        return this.httpBaseService.get<any>(
            `users-with-permission/identity-provider-list`
        );
    }

    public updateLastLogin(username: string): Observable<any> {
        return this.httpBaseService.put(
            `users-with-permission/update-last-login?username=${username}`
        );
    }

    public createUserWithPermissions(user) {
        const body = { ...user };
        return this.httpBaseService.post('users-with-permission', null, body);
    }

    public updateUserWithPermissions(user) {
        const body = { ...user };
        return this.httpBaseService.patch('users-with-permission', null, body);
    }

    public deleteUser(userId: string) {
        return this.httpBaseService.delete<any>(
            `users-with-permission/${userId}`
        );
    }

    public deleteSelectedUsers(userIdList: string[]) {
        const body: string[] = userIdList;
        return this.httpBaseService.delete<any>(
            `users-with-permission`,
            null,
            body
        );
    }

    public getLastSiteList(): Observable<any> {
        return this.httpBaseService.get<Site>(`filters/last-site`);
    }

    public updateLastSiteList(siteIdList: string[]) {
        return this.httpBaseService.patch(
            `filters/last-site`,
            null,
            siteIdList
        );
    }

    public exportSiteDatapoints(siteId: string): Observable<any> {
        return this.httpBaseService.getFile(
            `export/download-excel?siteId=${siteId}`
        );
    }

    private computeDatapointsFilters(
        filterParams: FilterParams,
        withGlobalFilters: boolean
    ) {
        let filterExpressions = [];
        const shouldExcludeSites = JSON.parse(
            sessionStorage.getItem('excludeSitesFilter')
        );

        if (
            !shouldExcludeSites &&
            withGlobalFilters &&
            this.getSelectedSiteList() &&
            this.getSelectedSiteList().length
        ) {
            filterExpressions.push(
                this.generateSiteRelations(this.getSelectedSiteList())
            );
        }

        if (filterParams.siteFilter && filterParams.siteFilter.length) {
            filterExpressions.push(
                this.generateSiteRelations(filterParams.siteFilter)
            );
        }

        if (filterParams.fieldFilter) {
            filterExpressions.push(
                ...this.generateFieldsExpressions(filterParams.fieldFilter)
            );
        }
        if (filterParams.quickFilterText) {
            filterExpressions.push(
                this.generateQuickFilterTextExpressions(
                    filterParams.quickFilterText,
                    filterParams.tagIdList
                )
            );
        }

        if (filterParams.deviceFilter && filterParams.deviceFilter.length) {
            filterExpressions.push(
                this.generateDeviceRelations(filterParams.deviceFilter)
            );
        }

        if (filterParams.gatewayFilter && filterParams.gatewayFilter.length) {
            filterExpressions.push({
                relation: {
                    field: 'dataPointEntity.device.gateway.id',
                    value: filterParams?.gatewayFilter.map((item) => item.id),
                    operator: IN,
                },
            });
        }

        if (filterParams.tagFilter && filterParams.tagFilter.length) {
            filterExpressions.push(
                this.generateTagsRelations(
                    filterParams.tagFilter,
                    filterParams.tagLogicalOperator
                )
            );
        }

        const selectedCustomer = this.getSelectedCustomer();
        if (selectedCustomer && withGlobalFilters) {
            filterExpressions.push(
                this.generateCustomerRelations(selectedCustomer)
            );
        }

        if (filterParams.dataPointIdFilter) {
            filterExpressions.push({
                relation: {
                    field: 'id',
                    operator: IN,
                    value: filterParams.dataPointIdFilter.map(
                        (dataPoint) => dataPoint.id
                    ),
                },
            });
        }

        let body = {
            includePresentValue: filterParams.includesPresentValue || false,
            filter: {
                boolean: {
                    operator: 'AND',
                    expressions: [...filterExpressions],
                },
            },
        };

        if (filterParams.isPolled) {
            body.filter.boolean.expressions.push({
                relation: {
                    field: 'polled',
                    operator: IS,
                    value: true,
                },
            });
        }
        return body;
    }

    private getSortablePath(path: string, sortOptions: SortOptions) {
        //TODO use this function in all the class
        if (sortOptions && sortOptions.sortField) {
            path = `${path}&sort=${sortOptions.sortField},${sortOptions.sortOrder}`;
        }
        if (sortOptions && sortOptions.email) {
            path = `${path}&email=${sortOptions.email}`;
        }
        return path;
    }

    public getSites(
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = PAGE_SIZE_1000,
        shouldFilterBySites: boolean = false,
        sortOptions?: SortOptions
    ) {
        let body = this.getRequestBodyWithBasicFilter();
        let path = this.getPageablePath(SITES_ENDPOINT, pageNumber, pageSize);
        if (sortOptions) {
            path = this.getSortablePath(path, sortOptions);
        }

        const selectedCustomer = this.getSelectedCustomer();
        if (selectedCustomer) {
            body.filter.boolean.expressions.push(
                this.getRelation('customer.id', IS, selectedCustomer.id)
            );
        }

        if (
            shouldFilterBySites &&
            this.getSelectedSiteList() &&
            this.getSelectedSiteList().length
        ) {
            body.filter.boolean.expressions.push(
                this.getRelation(
                    'id',
                    IN,
                    this.getSelectedSiteList().map((site) => site.id)
                )
            );
        }

        return this.httpBaseService.post<{
            content: SiteResponse[];
            totalElements: number;
        }>(path, null, body);
    }

    public getSitesByCustomer(customerId?: string) {
        let body = this.getRequestBodyWithBasicFilter();
        let path = this.getPageablePath(
            SITES_ENDPOINT,
            PAGINATION.DEFAULT_PAGE_NR,
            PAGE_SIZE_1000
        );

        body.filter.boolean.expressions.push(
            this.getRelation(
                'customer.id',
                IS,
                customerId || this.getSelectedCustomer()?.id
            )
        );

        return this.httpBaseService.post<{
            content: SiteResponse[];
            totalElements: number;
        }>(path, null, body);
    }

    public getAllAuditTrail(
        columnsToFilter: any,
        timeframeFilter: Date[],
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = PAGINATION.SIZE,
        sortOptions?: SortOptions
    ): Observable<{ content: AuditTrailResponse[]; totalElements: number }> {
        let body = this.generateFilterObject(columnsToFilter, timeframeFilter);

        let path = this.getPageablePath(
            AUDIT_TRAILS_ENDPOINT,
            pageNumber,
            pageSize
        );
        if (sortOptions) {
            // map here the sorting fields between core-API models
            sortOptions.sortField = auditTrailMapper[sortOptions.sortField];
            path = this.getSortablePath(path, sortOptions);
        }

        return this.httpBaseService.post<{
            content: AuditTrailResponse[];
            totalElements: number;
        }>(path, null, body);
    }

    public uploadSitesAndGateways(fileToUpload: File) {
        let formData: FormData = new FormData();
        formData.append('file', fileToUpload);

        return this.httpBaseService.post<any>(
            'import/upload-file-mass-onboard',
            null,
            formData,
            true,
            false
        );
    }

    public uploadSitesExcel(fileToUpload: File) {
        let formData: FormData = new FormData();
        formData.append('file', fileToUpload);

        return this.httpBaseService.post<any>(
            'import/site',
            null,
            formData,
            true,
            false
        );
    }

    public exportSitesExcel() {
        return this.httpBaseService.getFile(`export/site`);
    }

    public exportActionLogFile(
        body: IActionLogFileReq
    ): Observable<IExportFileRes> {
        return this.httpBaseService.postFile(
            `audit-trails/export`,
            null,
            body,
            null
        );
    }

    public uploadGatewaysExcel(fileToUpload: File) {
        let formData: FormData = new FormData();
        formData.append('file', fileToUpload);

        return this.httpBaseService.post<any>(
            'import/gateway',
            null,
            formData,
            true,
            false
        );
    }

    public exportGatewaysExcel() {
        return this.httpBaseService.getFile(`export/gateway`);
    }

    public unassignDevicesPointsFromSite(
        deviceIdList: string[]
    ): Observable<any> {
        let body = {
            deviceIdList,
            operation: 'UNASSIGN',
            siteId: null,
        };

        return this.httpBaseService.patch(
            `${DATAPOINT_ENDPOINTS}/update-site-by-device`,
            null,
            body
        );
    }

    public assignDevicesPointsToSite(
        siteId: string,
        deviceIdList: string[]
    ): Observable<any> {
        let body = {
            deviceIdList,
            operation: 'ASSIGN',
            siteId,
        };

        return this.httpBaseService.patch(
            `${DATAPOINT_ENDPOINTS}/update-site-by-device`,
            null,
            body
        );
    }

    public unassignDataPointsFromSite(
        datapointIdList: string[]
    ): Observable<any> {
        let body = {
            datapointIdList,
            operation: 'UNASSIGN',
            siteId: null,
        };

        return this.httpBaseService.patch(
            `${DATAPOINT_ENDPOINTS}/update-site`,
            null,
            body
        );
    }

    public assignDataPointsToSite(
        siteId: string,
        datapointIdList: string[]
    ): Observable<any> {
        let body = {
            datapointIdList,
            operation: 'ASSIGN',
            siteId,
        };

        return this.httpBaseService.patch(
            `${DATAPOINT_ENDPOINTS}/update-site`,
            null,
            body
        );
    }

    public uploadDatapoints(fileToUpload: File) {
        let formData: FormData = new FormData();
        formData.append('file', fileToUpload);
        return this.httpBaseService.post<any>(
            'import/upload-datapoints-file',
            null,
            formData,
            true,
            false
        );
    }

    public uploadDashboardTemplateExcel(fileToUpload: File) {
        const selectedCustomer = this.getSelectedCustomer();

        let formData: FormData = new FormData();
        formData.append('file', fileToUpload);
        return this.httpBaseService.post<any>(
            `import/dashboard-settings?customerId=${selectedCustomer.id}`,
            null,
            formData,
            true,
            false
        );
    }

    public deleteDashboardTemplate() {
        const selectedCustomer = this.getSelectedCustomer();
        return this.httpBaseService.delete<any>(
            `import/dashboard-settings?customerId=${selectedCustomer.id}`
        );
    }

    public exportDashboardTemplate() {
        const selectedCustomer = this.getSelectedCustomer();
        return this.httpBaseService.getFile(
            `export/dashboard-settings-excel-file?customerId=${selectedCustomer.id}`
        );
    }

    public getDashbardTemplateInfo() {
        const selectedCustomer = this.getSelectedCustomer();
        return this.httpBaseService.get<any>(
            `import/dashboard-settings-details?customerId=${selectedCustomer?.id}`
        );
    }

    public gatewayAutoOnboard(
        gatewayIdList: string[],
        pollingTypes: string[],
        siteId: string
    ) {
        let path = siteId
            ? `gateways/auto-onboard?autoPollingTypes=${pollingTypes}&secondsToWait=15&siteId=${siteId}`
            : `gateways/auto-onboard?autoPollingTypes=${pollingTypes}&secondsToWait=15`;
        return this.httpBaseService.put(path, null, gatewayIdList);
    }

    public checkUserNamePresence(username: string) {
        return this.httpBaseService.get(
            `user-account/details?username=${username}`
        );
    }

    public getGatewayProxyList() {
        return this.httpBaseService.get(`${GATEWAYS_ENDPOINT}/proxy-list`);
    }

    public getUserRole(username: string) {
        return this.httpBaseService.get(
            `user-account/role?username=${username}`
        );
    }

    public getUserRolePermissionList() {
        return this.httpBaseService.get(`user-account/role-permission-list`);
    }

    public getAlertRules(
        siteId?: string,
        customerId?: string
    ): Observable<AlertData[]> {
        const selectedCustomerId = customerId || this.getSelectedCustomer()?.id;
        const queryParams = siteId
            ? `customerId=${selectedCustomerId}&siteId=${siteId}`
            : `customerId=${selectedCustomerId}`;
        const result: Observable<ApiAlertData[]> = this.httpBaseService.get(
            `alarm-config?${queryParams}`
        );
        return result;
    }

    public getTriggeredAlerts(customerId: string): Observable<SmartAlert[]> {
        return this.httpBaseService.get(
            `alarm-instance?customerId=${customerId || this.getSelectedCustomer()?.id}`
        );
    }

    public changeAlertStatus(status: boolean, alarmConfigId: string) {
        return this.httpBaseService.patch(
            `alarm-config/enable-flag?alarmConfigId=${alarmConfigId}&enableFlag=${status}`
        );
    }

    public getAlertDetails(alarmInstanceId: string) {
        return this.httpBaseService.get(
            `alarm-instance/with-filter-matcher-result?alarmInstanceId=${alarmInstanceId}`
        );
    }

    public deleteAlert(idList: string[]) {
        const body = idList;
        return this.httpBaseService.delete<void>(`alarm-config`, null, body);
    }

    public createAlert(alertData: any) {
        const body = [alertData];
        return this.httpBaseService.post('alarm-config', null, body);
    }

    public createAlertTemplate(alertData: SmartAlertTemplateReq): Observable<any> {
        const body = alertData;
        return this.httpBaseService.post('alert-template', null, body);
    }

    public updateAlert(alertData: any) {
        const body = [alertData];
        return this.httpBaseService.patch('alarm-config', null, body);
    }

    public updateSmartAlertComment(alarmInstanceId: string, comment: string) {
        const body = { alarmInstanceId, comment };
        return this.httpBaseService.patch(
            'alarm-instance/edit-comment',
            null,
            body
        );
    }

    public changeAlertConsoleStatus(alarmInstanceId: string, status: string) {
        return this.httpBaseService.patch(
            `alarm-instance/update-status?alarmInstanceId=${alarmInstanceId}&status=${status}`
        );
    }

    private getPageablePath(
        domain: string,
        pageNumber: number,
        pageSize: number
    ) {
        //TODO use this function in all the class
        return `${domain}/filter?page=${pageNumber}&size=${pageSize}`;
    }

    private getSummaryPageablePath(
        domain: string,
        pageNumber: number,
        pageSize: number
    ) {
        //TODO use this function in all the class
        return `${domain}/filter-summary?page=${pageNumber}&size=${pageSize}`;
    }

    private generateSiteRelations(siteFilter: Site[]) {
        return {
            relation: {
                field: 'site.id',
                value: siteFilter.map((site) => site.id),
                operator: IN,
            },
        };
    }

    private generateCustomerRelations(selectedCustomer: Customer) {
        return {
            relation: {
                field: 'site.customer.id',
                value: selectedCustomer.id,
                operator: IS,
            },
        };
    }

    private getRelation(field: string, operator: string, values: any) {
        return {
            relation: {
                field: field,
                operator: operator,
                value: values,
            },
        };
    }

    private generateDeviceRelations(deviceFilter: ViewDevice[]) {
        return {
            relation: {
                field: 'device.id',
                value: deviceFilter.map((device) => device.id),
                operator: IN,
            },
        };
    }

    private generateTagsRelations(
        tagFilter: DataPointTag[],
        tagLogicalOperator: string
    ) {
        return {
            relation: {
                field: 'tags.name',
                operator: tagLogicalOperator === 'OR' ? IN : 'ALL',
                value: tagFilter.map((tag) => tag.name),
            },
        };
    }

    private generateFieldsExpressions(fieldsToFilter: FieldFilter) {
        return Object.keys(fieldsToFilter).map((key) => {
            if (fieldsToFilter[key].intervalStart) {
                return {
                    relation: {
                        field: this.mapUiFields(fieldsToFilter[key].field),
                        operator: fieldsToFilter[key].operator,
                        value: {
                            min: parseFloat(fieldsToFilter[key].intervalStart),
                            max: parseFloat(fieldsToFilter[key].intervalEnd),
                        },
                    },
                };
            }

            if (fieldsToFilter[key].field === FieldList.POLLED) {
                return {
                    relation: {
                        field: 'polled',
                        value: fieldsToFilter[key].value,
                        operator: fieldsToFilter[key].operator,
                    },
                };
            }

            if (fieldsToFilter[key].field === FieldList.SITE_ASSIGNED) {
                return {
                    relation: {
                        field: 'site.id',
                        operator:
                            fieldsToFilter[key].value === true
                                ? 'IS_NOT_NULL'
                                : 'IS_NULL',
                    },
                };
            }

            return {
                relation: {
                    field: this.mapUiFields(fieldsToFilter[key].field),
                    operator: fieldsToFilter[key].operator,
                    value: fieldsToFilter[key].value,
                },
            };
        });
    }

    private generateQuickFilterTextExpressions(
        quickFilterText: string,
        tagIdList: any
    ) {
        const quickFilter = {
            boolean: {
                operator: 'OR',
                expressions: [
                    {
                        relation: {
                            field: dataPointApiSortFieldMapper['objectName'],
                            operator: CONTAINS,
                            value: quickFilterText,
                        },
                    },
                    {
                        relation: {
                            field: 'units',
                            operator: CONTAINS,
                            value: quickFilterText,
                        },
                    },
                    {
                        relation: {
                            field: 'description',
                            operator: CONTAINS,
                            value: quickFilterText,
                        },
                    },
                    {
                        relation: {
                            field: 'userDescription',
                            operator: CONTAINS,
                            value: quickFilterText,
                        },
                    },
                ],
            },
        };
        if (tagIdList) {
            quickFilter.boolean.expressions.push(
                this.getRelation('tags.id', IN, tagIdList)
            );
        } else {
            quickFilter.boolean.expressions.push(
                this.getRelation('tags.name', CONTAINS, quickFilterText)
            );
        }
        return quickFilter;
    }

    private mapUiFields(field: string) {
        return {
            'Custom Name': 'userDescription',
            'Source Description': 'description',
            'IO-Type': 'type',
            'Signal Type': 'signalType',
            Source: 'source',
            'Last recorded value': 'lastRecordedValue',
            Name: 'name',
            Units: 'units',
            'Datapoint ID': 'id',
            Instance: 'instanceExtension',
            ID: 'sourceId',
            Classification: 'classificationExtension',
        }[field];
    }

    private getSelectedCustomer() {
        return JSON.parse(sessionStorage.getItem('selectedCustomer'));
    }

    private getSelectedSiteList() {
        return JSON.parse(sessionStorage.getItem('selectedSiteList'));
    }

    deleteTemplateById(templateId: string): Observable<void> {
        return this.httpBaseService.delete<void>(
            `schedule-templates/${templateId}`
        );
    }

    private mapResponseToDatapointCoreModel(value: {
        content: DataPointAPI[];
        totalElements: number;
    }): { content: DataPoint[]; totalElements: number } {
        let mapCCFields = function (dataPointAPI: DataPointAPI) {
            const datapoint = mapCommonFields(dataPointAPI);
            datapoint.instanceExtension =
                dataPointAPI.extensions && dataPointAPI.extensions.instance
                    ? dataPointAPI.extensions.instance.value
                    : null;
            return datapoint;
        };

        let mapCommonFields = function (dataPointAPI: DataPointAPI) {
            const datapoint: DataPoint = <DataPoint>(<unknown>dataPointAPI);
            datapoint.selected = false;
            datapoint.sourceId = dataPointAPI.externalId;
            datapoint.presentValue = dataPointAPI.lastRecordedValue;
            datapoint.presentValueTimestamp = moment
                .tz(
                    dataPointAPI.lastRecordedValueTimestamp,
                    dataPointAPI.site?.timezone || 'Europe/London'
                )
                .format('DD.MM.YYYY, HH:mm:ss');
            datapoint.deviceName = dataPointAPI.deviceName
                ? dataPointAPI.deviceName
                : dataPointAPI['device']['name'];
            datapoint.objectName = dataPointAPI.name;
            datapoint.deviceSiteName = dataPointAPI?.site?.name;
            datapoint.sourceType = dataPointAPI.sourceType;
            datapoint.isCCSource =
                ExternalSource.BACKNET === dataPointAPI.source;
            datapoint.isWiseMeterSource =
                ExternalSource.WISEMETER === dataPointAPI.source;
            return datapoint;
        };
        let mapWiseMeterFields = function (dataPointAPI: DataPointAPI) {
            const datapoint = mapCommonFields(dataPointAPI);
            datapoint.classificationExtension =
                dataPointAPI.extensions &&
                dataPointAPI.extensions.classification
                    ? dataPointAPI.extensions.classification.value
                    : null;
            return datapoint;
        };

        const toDatapointCoreModel = function (
            dataPointAPI: DataPointAPI
        ): DataPoint {
            if (ExternalSource.BACKNET === dataPointAPI.source) {
                return mapCCFields(dataPointAPI);
            }
            if (ExternalSource.WISEMETER === dataPointAPI.source) {
                return mapWiseMeterFields(dataPointAPI);
            }
        };

        const response = value.content.map((value1) =>
            toDatapointCoreModel(value1)
        );
        return { content: response, totalElements: value.totalElements };
    }

    syncDatapointsWithOptimumCommercial(selectedDataPoints: string[]) {
        return this.httpBaseService.patch(
            `${DATAPOINT_ENDPOINTS}/sync-with-optimum-commercial`,
            null,
            selectedDataPoints
        );
    }

    getCustomerAutoOptimisationConfig(): Observable<any> {
        return this.httpBaseService.get(
            `api/v1/customer-auto-optimisation/configs?customerId=${this.getSelectedCustomer().id}`
        );
    }

    updateTwinDatapointOptimisationEnabled(value: boolean) {
        return this.httpBaseService.patch(
            `api/v1/customer-auto-optimisation/configs/twinDatapointOptimisationEnabled?customerId=${this.getSelectedCustomer().id}&value=${value}`
        );
    }
    updateTwinScheduleOptimisationEnabled(value: boolean) {
        return this.httpBaseService.patch(
            `api/v1/customer-auto-optimisation/configs/twinScheduleOptimisationEnabled?customerId=${this.getSelectedCustomer().id}&value=${value}`
        );
    }

    updateInterceptValue(
        dataPointId: string,
        body: IDataPointProperty[]
    ): Observable<IUpdateDataPointPropertiesRes> {
        let path = `data-points/${dataPointId}/properties`;
        return this.httpBaseService.patch(path, null, body);
    }
}

export const getPageSortPair = (event: LazyLoadEvent, pageSize: number) => {
    const pageNumber = event.first / pageSize;
    const sortOptions = {
        sortField: event.sortField,
        sortOrder: getSortOrder(event.sortOrder),
    };
    return { pageNumber, sortOptions };
};

//TODO move this in getSites
export function getSiteList(response: {
    content: SiteResponse[];
    totalElements: number;
}): Site[] {
    return response.content.map((value) =>
        Object.assign(
            {},
            {
                id: value.id,
                name: value.name,
                readOnly: value.readOnly,
                siteOverviewEnabled: value.siteOverviewEnabled,
                customerId: value.customer.id,
                customerName: value.customer.name,
                dashboardEnabled: value.dashboardEnabled,
                selected: false,
                tags: value.tags,
                timezone: value.timezone,
            }
        )
    );
}
