import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Site } from '../../models/site';
import _ from 'lodash';
import { BackendService } from 'src/app/services/backend/backend.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
    selector: 'cbms-sites-selection',
    templateUrl: './sites-selection.component.html',
    styleUrls: ['./sites-selection.component.scss'],
})
export class SitesSelectionComponent implements OnInit {
    public sites: Site[] = [];
    public selectedSites: Site[] = [];

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig
    ) {}

    ngOnInit(): void {
        this.sites = _.cloneDeep(this.config.data);
        this.selectedSites = this.sites.filter((item) => item.selected);
    }

    handleValueChange() {
        this.sites.map((site) => {
            this.selectedSites.findIndex(
                (selectedSite) => selectedSite.id === site.id
            ) >= 0
                ? (site.selected = true)
                : (site.selected = false);
        });
    }


    close() {
        this.ref.close();
    }

    saveSelectedSites() {
        this.ref.close(this.selectedSites);
    }
}
