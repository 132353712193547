import { TreeNode } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UtilsService } from '../../services/utils/util.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cbms-detail-action-log',
    templateUrl: './detail-action-log.component.html',
    styleUrl: './detail-action-log.component.scss',
})
export class DetailActionLogComponent implements OnInit {
    public isLoadingProperties: boolean = false;
    public properties: TreeNode[];
    propertiesOrder = {
        userName: 1,
        tenantName: 2,
        customerName: 3,
        siteName: 4,
        gatewayName: 5,
        deviceName: 6,
        operationId: 7,
        dataPointName: 8,
        dataPointDescription: 9,
        dataPointType: 10,
        dataPointId: 11,
        actionCategory: 12,
        action: 13,
        actionTimestamp: 14,
        actionResult: 15,
        valueBefore: 16,
        valueAfter: 17,
        other: 18,
    };
    public editing: { [key: string]: boolean } = {};
    public backupData: { [key: string]: number } = {};
    dataPointId: string;
    isInterceptValueValid: boolean = false;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
        this.isLoadingProperties = true;
        if (!this.config.data) {
            this.properties = null;
        } else {
            this.properties = this.utilsService.orderListByFieldName(
                this.transformProperties(this.config.data),
                'position'
            );
        }
        this.isLoadingProperties = false;
    }

    transformProperties(dataPointProperties) {
        let fieldList = Object.keys(dataPointProperties).map((key) => {
            let item: any = {
                position: this.propertiesOrder[key]
                    ? this.propertiesOrder[key]
                    : this.propertiesOrder.other,
            };
            item.data = {
                name: key,
                value: JSON.stringify(dataPointProperties[key]),
            };

            return item;
        });
        // remove unnecessary fields from the array
        fieldList = fieldList.filter(element => element.position !== this.propertiesOrder.other);
        return fieldList;
    }

    parsePriorityArrayValue(value) {
        if (value === 0) {
            return 0;
        }
        return value ? value : 'Null';
    }

    close() {
        this.ref.close();
    }
}
