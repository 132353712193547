import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { PrimeNGCorrectionService } from 'src/app/services/utils/primeNgCorrection.service';
import { SelectItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { v4 } from 'uuid';
import { FilterService } from '../filter/filter.service';
import { DataService } from 'src/app/services/data/data.service';
import { ColumnsToFilter } from 'src/app/models/columns-filter.interface';

@Component({
	selector: 'cbms-column-filter',
	templateUrl: './column-filter.component.html',
	styleUrls: ['./column-filter.component.scss']
})
export class ColumnFilterComponent implements OnInit {

	public hasFilters = false;
	public addFilterForm: FormGroup;
	public filterFormTitle: string = 'Add Filter';
	public columnList: SelectItem[] = [
		{ label: 'Select a field', value: '' },
		{ label: 'User', value: 'userName' },
		{ label: 'Tenant', value: 'tenantName' },
		{ label: 'Site', value: 'siteName' },
		{ label: 'Customer', value: 'customerName' },
		{ label: 'Gateway', value: 'gatewayName' },
		{ label: 'Device', value: 'deviceName' },
		{ label: 'Operation ID', value: 'operationId' },
		{ label: 'Datapoint Name', value: 'dataPointName' },
		{ label: 'Datapoint Description', value: 'dataPointDescription' },
		{ label: 'Datapoint Type', value: 'dataPointType' },
		{ label: 'Datapoint ID', value: 'dataPointId' },
		{ label: 'Action Category', value: 'actionCategory' },
		{ label: 'Action', value: 'action' },
		{ label: 'Timestamp', value: 'actionTimestamp' },
		{ label: 'Action Message (Success / Failure and Message)', value: 'actionResult' },
		{ label: 'Value Before Change', value: 'valueBefore' },
		{ label: 'Value After Change', value: 'valueAfter' }
	];

	public operatorsList: SelectItem[] = [
		{ label: 'contains', value: 'contains' }
	];

	public selectedColumns: ColumnsToFilter = {};

	private currentlyEditingField: string = null;
	private tabName = 'actionLog';

	constructor(private formBuilder: FormBuilder,
		private filterService: FilterService,
		private dataService: DataService,
		primeNgCorrectionService: PrimeNGCorrectionService) { }

	ngOnInit(): void {
		this.addFilterForm = this.formBuilder.group({
			columnName: new FormControl('', Validators.required),
			columnValue: new FormControl('', Validators.required),
			selectedOperator: new FormControl('contains'),
		});

		this.columnName.valueChanges.subscribe(value => {
			if (value === 'operationId' || value === 'dataPointId') {
				this.operatorsList = [{ label: 'is', value: 'is' }];
				this.selectedOperator.setValue('is');
			} else {
				this.operatorsList = [{ label: 'contains', value: 'contains' }];
				this.selectedOperator.setValue('contains');
			}
		});

		this.selectedColumns = this.filterService.getSelectedFilter('columnsFilter', this.tabName) || {};
		this.setHasFilterFlag();

		this.dataService.applyColumnsfilter(this.selectedColumns);
	}

	get columnName() {
		return this.addFilterForm.get('columnName') as FormControl;
	}
	get columnValue() {
		return this.addFilterForm.get('columnValue') as FormControl;
	}
	get selectedOperator() {
		return this.addFilterForm.get('selectedOperator') as FormControl;
	}

	public saveFilter(filterMenuOverlay: OverlayPanel) {
		let formValues = this.addFilterForm.value;
		let columnValue = formValues.columnValue;

		let newFilter = {
			column: formValues.columnName,
			value: columnValue.trim(),
			operator: formValues.selectedOperator
		};

		let key = this.currentlyEditingField || v4();
		this.selectedColumns[key] = newFilter;
		this.currentlyEditingField = null;
		this.setHasFilterFlag();
		filterMenuOverlay.hide();
	}

	public resetAddFilterForm() {
		this.currentlyEditingField = null;
		this.addFilterForm.reset();
	}

	public editColumnToFilter(event, item: any, filterMenuOverlay: OverlayPanel) {
		this.filterFormTitle = 'Edit Filter';
		this.currentlyEditingField = item.key;
		filterMenuOverlay.hide();

		setTimeout(() => {
			this.addFilterForm.patchValue({
				columnName: item.value.column,
				columnValue: item.value.value || ''
			});
			filterMenuOverlay.show(event, event.target);
		});

	};

	public removeColumnFromFilter(column: any) {
		delete this.selectedColumns[column.key];
		this.setHasFilterFlag();
		if (!this.hasFilters) {
			this.filterService.clearFilters(this.tabName, { columnsFilter: this.selectedColumns });
			this.dataService.clearColumnsFilter();
		}
	}

	public showFilterMenu(event, filterMenuOverlay: OverlayPanel) {
		this.filterFormTitle = 'Add Filter';
		this.resetAddFilterForm();
		filterMenuOverlay.hide();
		setTimeout(() => {
			filterMenuOverlay.show(event, event.target);
		});
	}

	public onFilter() {
		this.filterService.applyFilter(this.tabName, { columnsFilter: this.selectedColumns });
		this.dataService.applyColumnsfilter(this.selectedColumns);
	}

	public clearFilters() {
		this.selectedColumns = {};
		this.hasFilters = false;
		this.filterService.clearFilters(this.tabName, { columnsFilter: this.selectedColumns });
		this.dataService.clearColumnsFilter();
	}

	private setHasFilterFlag() {
		this.hasFilters = !!Object.keys(this.selectedColumns).length;
	}
}
