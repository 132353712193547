import { Component, OnInit, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ExceptionScheduleTemplate } from 'src/app/models/data-point';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BackendService } from '../../services/backend/backend.service';
import { UtilsService } from '../../services/utils/util.service';
import { NotificationService } from '../../services/notification/notification.service';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ANY, WEEK_OF_MONTH_TEXT, DayOfWeek, Months, TRUE, ANALOG, BINARY, MULTISTATE } from '../../config/constants';
import moment from 'moment';
import _ from 'lodash';
import { v4 } from 'uuid';
import { Table } from 'primeng/table/table';

const StartTimeValidator: ValidatorFn = (fg: FormGroup) => {
    const start = fg.get('startTime').value;
    const stop = fg.get('stopTime').value;
    return start !== null && stop !== null && start < stop
        ? null
        : { startTime: 'not valid' };
};

const StopTimeValidator: ValidatorFn = (fg: FormGroup) => {
    const start = fg.get('startTime').value;
    const stop = fg.get('stopTime').value;
    return start !== null && stop !== null && stop > start
        ? null
        : { stopTime: 'not valid' };
};

const periodValueValidator: ValidatorFn = (fg: FormGroup) => {
	const signalType = fg.get('selectedSignalType');
	const periodValue = fg.get('periodValue');

	if (signalType.value === BINARY) {
		return null;
	}

	if ((signalType.value === ANALOG || signalType.value === MULTISTATE) && isNaN(periodValue.value)) {
		return { periodValue: 'Only numbers are allowed.'}
	}

 	if (signalType.value === MULTISTATE && periodValue.value.toString(10).indexOf('.') !== -1) {
		return { periodValue: 'Decimal value not allowed for multistate signal.' }
	}
};

@Component({
    selector: 'cbms-exception-schedule-template',
    templateUrl: './exception-schedule-template.component.html',
    styleUrls: ['./exception-schedule-template.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExceptionScheduleTemplateComponent implements OnInit {
    @ViewChild('eventsTable') eventTable: Table;

    public isReadonly: boolean;
    public isLoading: boolean = false;
    public isRequestDone: boolean = true;
    public template: ExceptionScheduleTemplate;
    public saveExceptionEventForm: FormGroup;
    public saveEventPeriodForm: FormGroup;
    public selectedEvent: any;
    public selectedPeriod: any;
    public showEventForm: boolean = false;
    public showPeriodForm: boolean = false;
    public isEditingPeriod: boolean = false;
    public isEditingEvent: boolean = false;

    public signalTypeList: SelectItem[] = [
		{ label: 'Select signal type', value: '' },
		{ label: 'Analog', value: ANALOG },
		{ label: 'Binary', value: BINARY },
		{ label: 'Multistate', value: MULTISTATE }
	];

	public binaryTypeList: SelectItem[] = [
		{ label: 'Select value', value: '' },
		{ label: 'On', value: 1 },
		{ label: 'Off', value: 0 }
	];

    public eventTypeList: SelectItem[] = [
        { label: 'Select type', value: '' },
        { label: 'Single Date', value: 'date', disabled: false },
        { label: 'Date Range', value: 'dateRange', disabled: false },
        { label: 'Calendar Reference', value: 'calendarReference', disabled: false },
        { label: 'Week and Day', value: 'weekNDay', disabled: false },
    ];

    public dayList: SelectItem[] = [
        { label: 'Day', value: '' },
        { label: 'Any', value: DayOfWeek.Any }
    ];

    public yearList: SelectItem[] = [
        { label: 'Year', value: '' },
        { label: 'Any', value: DayOfWeek.Any },
        { label: '2020', value: 2020 },
        { label: '2021', value: 2021 },
        { label: '2022', value: 2022 },
        { label: '2023', value: 2023 },
        { label: '2024', value: 2024 },
        { label: '2025', value: 2025 },
    ];

    public weekList: SelectItem[] = [
        { label: 'Week', value: '' },
        { label: WEEK_OF_MONTH_TEXT[0], value: 0 },
        { label: WEEK_OF_MONTH_TEXT[1], value: 1 },
        { label: WEEK_OF_MONTH_TEXT[2], value: 2 },
        { label: WEEK_OF_MONTH_TEXT[3], value: 3 },
        { label: WEEK_OF_MONTH_TEXT[4], value: 4 },
        { label: WEEK_OF_MONTH_TEXT[5], value: 5 },
        { label: WEEK_OF_MONTH_TEXT[6], value: 6 },
        { label: WEEK_OF_MONTH_TEXT[7], value: 7 },
        { label: WEEK_OF_MONTH_TEXT[8], value: 8 },
        { label: WEEK_OF_MONTH_TEXT[9], value: 9 },
    ];

    public weekDayList: SelectItem[] = [
        { label: 'Day', value: '' },
        { label: DayOfWeek[0], value: DayOfWeek.Any },
        { label: DayOfWeek[1], value: DayOfWeek.Monday },
        { label: DayOfWeek[2], value: DayOfWeek.Tuesday },
        { label: DayOfWeek[3], value: DayOfWeek.Wednesday },
        { label: DayOfWeek[4], value: DayOfWeek.Thursday },
        { label: DayOfWeek[5], value: DayOfWeek.Friday },
        { label: DayOfWeek[6], value: DayOfWeek.Saturday },
        { label: DayOfWeek[7], value: DayOfWeek.Sunday },
    ];

    public monthList: SelectItem[] = [
        { label: 'Month', value: '' },
        { label: Months[0], value: Months.Any },
        { label: Months[1], value: Months.January },
        { label: Months[2], value: Months.February },
        { label: Months[3], value: Months.March },
        { label: Months[4], value: Months.April },
        { label: Months[5], value: Months.May },
        { label: Months[6], value: Months.June },
        { label: Months[7], value: Months.July },
        { label: Months[8], value: Months.August },
        { label: Months[9], value: Months.September },
        { label: Months[10], value: Months.October },
        { label: Months[11], value: Months.November },
        { label: Months[12], value: Months.December },
    ];

    public mainTableCols = [
        { field: 'name', header: 'Name', sortable: false },
        { field: 'type', header: 'Type', sortable: false },
        { field: 'summary', header: 'Summary', sortable: false },
        { field: 'priority', header: 'Priority', sortable: true },
        { field: 'actions', header: 'Actions', sortable: false }
    ];

    public subTableCols = [
        { field: 'start', header: 'Start', sortable: true },
        { field: 'end', header: 'Stop', sortable: true },
        { field: 'value', header: 'Value', sortable: true },
        { field: 'type', header: 'Type', sortable: true },
        { field: 'actions', header: 'Actions', sortable: false }
    ];

    public eventPeriodType = {
        'Single Date': 'date',
        'Date Range': 'dateRange',
        'Calendar Reference': 'calendarReference',
        'Week and Day': 'weekNDay'
    }

    public periodTypeName = {
        date: 'Single Date',
        dateRange: 'Date Range',
        weekNDay: 'Week and Day',
        calendarReference: 'Calendar Reference'
    };

    public showInvalidDateError = {
        singleDay: false,
        rangeStartDate: false,
        rangeEndDate: false
    };
    public showInvalidRangeError = false;

    public singleDay = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined
    };

    public dateRangeStartDate = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined
    };

    public dateRangeEndDate = {
        selectedDay: undefined,
        selectedMonth: undefined,
        selectedYear: undefined
    };

    private emptyDate = {
        selectedDay: '',
        selectedMonth: '',
        selectedYear: ''
    }

    constructor(public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private utilsService: UtilsService,
        private formBuilder: FormBuilder,
        private notificationService: NotificationService,
        private backendService: BackendService) { }

    ngOnInit() {

        this.generateDayList();

        this.template = {
            name: this.config.data.name,
            id: this.config.data.id,
            exceptionScheduleObject: this.utilsService.mapExceptionSchedule(_.cloneDeep(this.config.data.exceptionScheduleObject))
        };

        let selectedSignalType = this.getPeriodSignalType(this.template.exceptionScheduleObject);

        const startTime = moment().set('minute', 0).toDate();
        const stopTime = moment(startTime).add(1, 'hours').toDate();

        this.saveExceptionEventForm = this.formBuilder.group({
            eventName: new FormControl('', Validators.required),
            eventPriority: new FormControl('', [Validators.required, Validators.min(1), Validators.max(16), Validators.pattern("^[0-9]*$")]),
            selectedEventType: new FormControl('date', Validators.required)
        });

        this.saveEventPeriodForm = this.formBuilder.group({
            startTime: new FormControl(startTime, [Validators.required]),
            stopTime: new FormControl(stopTime, [Validators.required]),
            selectedSignalType: new FormControl([{value: selectedSignalType, disabled: true}], Validators.required),
            periodValue: new FormControl('', Validators.required),
            allDayPeriod: new FormControl(false)
        }, { validators: [StartTimeValidator, StopTimeValidator, periodValueValidator] });


        this.allDayPeriod.valueChanges.subscribe(value => {
            if (value) {
                this.startTime.setValue(moment('00:00:00', 'HH:mm:ss').toDate());
                this.stopTime.setValue(moment('23:59:00', 'HH:mm:ss').toDate());
                this.startTime.disable();
                this.stopTime.disable();
            }
            if (!value) {
                const start = moment().set('minute', 0).toDate();
                this.startTime.enable();
                this.stopTime.enable();
                this.startTime.setValue(start);
                this.stopTime.setValue(moment(start).add(1, 'hours').toDate());
            }
        });

        this.selectedEventType.valueChanges.subscribe(value => {
            switch (value) {
                case 'date':
                    this.switchToDate();
                    break;
                case 'dateRange':
                    this.switchToDateRange();
                    break;
                case 'calendarReference':
                    this.switchToCalendar();
                    break;
                case 'weekNDay':
                    this.switchToWeekNDay();
                    break;
                default:
                    break;
            };
        });
        this.isReadonly = this.config.data.isReadonly;

        this.selectedSignalType.valueChanges.subscribe(value => {
            if (value !== this.selectedSignalType.value) {
                this.periodValue.setValue('');
            }
		});
    }

    get selectedEventType() {
        return this.saveExceptionEventForm.get('selectedEventType') as FormControl;
    }

    get selectedSignalType() {
		return this.saveEventPeriodForm.get('selectedSignalType') as FormControl;
	}

    get startTime() {
        return this.saveEventPeriodForm.get('startTime') as FormControl;
    }
    get stopTime() {
        return this.saveEventPeriodForm.get('stopTime') as FormControl;
    }
    get allDayPeriod() {
        return this.saveEventPeriodForm.get('allDayPeriod') as FormControl;
    }
    get periodValue() {
		return this.saveEventPeriodForm.get('periodValue') as FormControl;
	}

    public addEvent() {
        this.showEventForm = true;
        this.showPeriodForm = false;
        this.selectedPeriod = '';
        this.resetDatesErrorMessage();
        this.saveExceptionEventForm.markAsPristine();
        this.saveExceptionEventForm.updateValueAndValidity();
    }

    public addPeriod(event) {
        let selectedSignalType = this.getPeriodSignalType(this.template.exceptionScheduleObject);

        if (selectedSignalType) {
            this.selectedSignalType.setValue(selectedSignalType);
            this.selectedSignalType.disable();
        } else {
            this.selectedSignalType.enable();
            this.selectedSignalType.setValue('');
        }

        this.selectedEvent = event;
        this.showPeriodForm = true;
        this.showEventForm = false;
        this.resetDatesErrorMessage();
        this.saveEventPeriodForm.markAsPristine();
        this.saveEventPeriodForm.updateValueAndValidity();
    }

    public saveEvent() {
        this.resetDatesErrorMessage();
        const formValues = this.saveExceptionEventForm.value;

        if (formValues.selectedEventType === 'date' && !this.isValidDate(this.singleDay)) {
            this.showInvalidDateError.singleDay = true;
            return;
        }
        if (formValues.selectedEventType === 'dateRange') {
            if (!this.isValidDate(this.dateRangeStartDate) || !this.isValidDate(this.dateRangeEndDate)) {
                this.showInvalidDateError.rangeStartDate = !this.isValidDate(this.dateRangeStartDate);
                this.showInvalidDateError.rangeEndDate = !this.isValidDate(this.dateRangeEndDate);
                return;
            }
            if (!this.isValidRangeDate(this.dateRangeStartDate, this.dateRangeEndDate)) {
                this.showInvalidRangeError = true;
                return;
            }
        }

        if (this.eventsWithSamePriority(formValues.eventPriority)) {
            this.notificationService.addWarnMessage('Template', 'Two or more events have the same priority, this might create conflicts in the device', true);
        }

        if (this.selectedEvent) {
            this.template.exceptionScheduleObject.forEach(item => {
                if (item.id === this.selectedEvent.id) {
                    item.name = formValues.eventName;
                    item.priority = formValues.eventPriority;
                    item.type = this.periodTypeName[formValues.selectedEventType];
                    item.period = this.generatePeriod(formValues);
                    item.summary = this.utilsService.generateExceptionScheduleEventSummary(item, formValues.selectedEventType);
                }
            })
            this.template.exceptionScheduleObject = [...this.template.exceptionScheduleObject];
            this.resetDatesErrorMessage();
            this.resetSaveExceptionEventForm();
            this.showEventForm = false;
            this.isEditingEvent = false;
            this.selectedEvent = '';
            return;
        }

        let event = {
            name: formValues.eventName,
            timeValues: [],
            priority: formValues.eventPriority,
            period: this.generatePeriod(formValues),
            summary: '',
            type: this.periodTypeName[formValues.selectedEventType],
            id: v4()
        };
        event.summary = this.utilsService.generateExceptionScheduleEventSummary(event, formValues.selectedEventType);

        this.template.exceptionScheduleObject.push(event);
        this.template.exceptionScheduleObject = [...this.template.exceptionScheduleObject];

        this.resetDatesErrorMessage();
        this.resetSaveExceptionEventForm();
        this.showEventForm = false;
    }

    private generatePeriod(event) {
        switch (event.selectedEventType) {
            case 'date':
                return this.generateSingleDate();
            case 'dateRange':
                return this.generateDateRange();
            case 'calendarReference':
                return this.generateCalendarReference(event);
            case 'weekNDay':
                return this.generateWeekAndDay(event);
            default:
                break;
        };
    }

    private generateSingleDate() {
        return {
            date: {
                year: this.parseValue(this.singleDay.selectedYear),
                month: this.parseValue(this.singleDay.selectedMonth),
                day: this.parseValue(this.singleDay.selectedDay)
            }
        }
    }

    private generateDateRange() {
        return {
            dateRange: {
                endDate: {
                    year: this.parseValue(this.dateRangeEndDate.selectedYear),
                    month: this.parseValue(this.dateRangeEndDate.selectedMonth),
                    day: this.parseValue(this.dateRangeEndDate.selectedDay)
                },
                startDate: {
                    year: this.parseValue(this.dateRangeStartDate.selectedYear),
                    month: this.parseValue(this.dateRangeStartDate.selectedMonth),
                    day: this.parseValue(this.dateRangeStartDate.selectedDay)
                }
            }
        }
    }

    private parseValue(value: any) {
        return parseInt(value) === 0 ? null : value;
    }

    private generateCalendarReference(event) {
        return {
            calendarReference: {
                type: "calendar",
                instance: parseInt(event.eventCalendarRef)
            }
        }
    }

    private generateWeekAndDay(event) {
        return {
            weekNDay: {
                dayOfWeek: event.selectedEventDay === 0 ? null : event.selectedEventDay,
                month: event.selectedEventMonth === 0 ? null : event.selectedEventMonth,
                weekOfMonth: event.selectedEventWeek === 0 ? null : event.selectedEventWeek
            }
        }
    }

    private eventsWithSamePriority(eventPriority: number) {
        return this.template.exceptionScheduleObject.findIndex(item => {
            if (this.selectedEvent && this.selectedEvent.id === item.id) {
                return false;
            }
            return item.priority === eventPriority;
        }) !== -1;
    }

    public savePeriod() {
        const formValues = this.saveEventPeriodForm.getRawValue();
        let start = '';
        let end = '';

        if (formValues.allDayPeriod) {
            start = moment().startOf("day").seconds(0).milliseconds(0).format('HH:mm:ss');
            end = moment().endOf("day").seconds(0).milliseconds(0).format('HH:mm:ss');
        } else {
            start = moment(formValues.startTime).seconds(0).milliseconds(0).format('HH:mm:ss');
            end = moment(formValues.stopTime).seconds(0).milliseconds(0).format('HH:mm:ss');
        }

        if (!this.isValidPeriod({ start, end })) {
            this.notificationService.addErrorMessage('Template', 'Period cannot be added because it overlaps with existing ones', false);
            return;
        }

        if (this.selectedPeriod) {
            this.selectedEvent.timeValues.forEach(item => {
                if (item.id === this.selectedPeriod.id) {
                    item.end = end;
                    item.start = start;
                    item.type = formValues.selectedSignalType;
                    item.value = Number(formValues.periodValue);
                }
            });
            this.resetSaveEventPeriodForm();
            this.showPeriodForm = false;
            this.isEditingPeriod = false;
            this.selectedEvent = '';
            this.selectedPeriod = '';
            return;
        }

        let period = {
            end: end,
            start: start,
            type: formValues.selectedSignalType,
            value: Number(formValues.periodValue)
        };
        this.selectedEvent.timeValues.push(period);
        this.resetSaveEventPeriodForm();
        this.showPeriodForm = false;
    }

    public resetSaveExceptionEventForm() {
        this.singleDay = { ...this.emptyDate };
        this.dateRangeStartDate = { ...this.emptyDate };
        this.dateRangeEndDate = { ...this.emptyDate };

        this.saveExceptionEventForm.patchValue({
            'eventName': '',
            'eventPriority': ''
        });
    }

    public resetSaveEventPeriodForm() {
        const startTime = new Date();
        const stopTime = moment(startTime).add(1, 'hours').toDate();

        this.saveEventPeriodForm.patchValue({
            'startTime': startTime,
            'stopTime': stopTime,
            'periodValue': '',
            'allDayPeriod': false,
            'selectedSignalType': this.getPeriodSignalType(this.template.exceptionScheduleObject)
        });
    }

    public deletePeriod(event: MouseEvent, periodId: string, eventId: string) {
        event.stopPropagation();
        let scheduleEvent = this.template.exceptionScheduleObject.find(event => event.id === eventId);
        const eventPeriodIndex = scheduleEvent.timeValues.findIndex(eventPeriod => eventPeriod.id === periodId);
        scheduleEvent.timeValues.splice(eventPeriodIndex, 1);
        scheduleEvent.timeValues = [...scheduleEvent.timeValues];
    }

    public deleteEvent(event: MouseEvent, eventId: string) {
        event.stopPropagation();
        const scheduleEventIndex = this.template.exceptionScheduleObject.findIndex(event => event.id === eventId);
        this.template.exceptionScheduleObject.splice(scheduleEventIndex, 1);
        this.template.exceptionScheduleObject = [...this.template.exceptionScheduleObject];
    }

    public onEventSelect(event) {
        this.selectedPeriod = '';
        this.isEditingEvent = true;
        this.showEventForm = true;

        this.isEditingPeriod = false;
        this.showPeriodForm = false;
        this.resetDatesErrorMessage();
        this.resetSaveEventPeriodForm();

        const eventData = event.data;
        let periodType = Object.keys(eventData.period)[0];

        this.saveExceptionEventForm.patchValue({
            'eventName': eventData.name,
            'selectedEventType': periodType,
            'eventPriority': eventData.priority,
        });
    }

    public onEventUnselect() {
        this.isEditingEvent = false;
        this.showEventForm = false;
        this.isEditingPeriod = false;
        this.showPeriodForm = false;
        this.selectedPeriod = '';
        this.resetDatesErrorMessage();
        this.resetSaveExceptionEventForm();
        this.resetSaveEventPeriodForm();
    }

    public onPeriodSelect($event, event) {
        this.selectedEvent = event;
        this.isEditingPeriod = true;
        this.showPeriodForm = true;

        this.isEditingEvent = true;
        this.showEventForm = false;
        this.resetDatesErrorMessage();
        this.resetSaveExceptionEventForm();

        let startTime = moment(this.selectedPeriod.start, 'HH:mm:ss').toDate();
        let stopTime = moment(this.selectedPeriod.end, 'HH:mm:ss').toDate();

        this.saveEventPeriodForm.patchValue({
            'startTime': startTime,
            'stopTime': stopTime,
            'selectedSignalType': this.selectedPeriod.type,
            'periodValue': this.selectedPeriod.value
        });
        this.selectedSignalType.disable();
    }

    public onPeriodUnselect() {
        this.isEditingPeriod = false;
        this.isEditingEvent = false;
        this.showPeriodForm = false;
        this.selectedEvent = '';
        this.resetSaveEventPeriodForm();
    }

    public saveExceptionScheduleChanges() {
        if (this.template.id) {
            //update Exception Schedule Template
            this.backendService.updateExceptionScheduleTemplate(this.template).subscribe(response => {
                this.ref.close(this.template.id);
                this.notificationService.addSuccessMessage('Template', 'Template successfully updated', false);
            }, (e) => {
                this.notificationService.addErrorMessage('Template', e);
            })
        } else {
            //create Exception Schedule Template
            this.backendService.saveScheduleAsTemplate(this.template).subscribe((response) => {
                this.ref.close(response.id);
                this.notificationService.addSuccessMessage('Template', 'Template successfully saved', false);
            }, (e) => {
                this.notificationService.addErrorMessage('Template', e);
            });

        }

    }

    public close() {
        this.ref.close();
    }

    private switchToDate() {
        let eventDate = this.selectedEvent && this.eventPeriodType[this.selectedEvent.type] === 'date' ? this.selectedEvent.summary.replaceAll('*', '0') : '';
        let splittedDate = eventDate.split('.').map((item: string) => parseInt(item));

        this.singleDay = {
            selectedDay: splittedDate[0],
            selectedMonth: splittedDate[1],
            selectedYear: splittedDate[2]
        }

        this.saveExceptionEventForm.removeControl('eventCalendarRef');
        this.removeWeekNDayFields();
    }

    private switchToDateRange() {
        let eventStartDateArray = [];
        let eventEndDateArray = [];
        if (this.selectedEvent && this.eventPeriodType[this.selectedEvent.type] === 'dateRange') {
            let dates = this.selectedEvent.summary.split(' - ');
            eventStartDateArray = dates[0].replaceAll('*', '0').split('.').map((item: string) => parseInt(item));
            eventEndDateArray = dates[1].replaceAll('*', '0').split('.').map((item: string) => parseInt(item));
        }

        this.dateRangeStartDate = {
            selectedDay: eventStartDateArray[0],
            selectedMonth: eventStartDateArray[1],
            selectedYear: eventStartDateArray[2]
        }
        this.dateRangeEndDate = {
            selectedDay: eventEndDateArray[0],
            selectedMonth: eventEndDateArray[1],
            selectedYear: eventEndDateArray[2]
        }

        this.saveExceptionEventForm.removeControl('eventCalendarRef');
        this.removeWeekNDayFields();
    }

    private switchToCalendar() {
        let calendarRef = this.selectedEvent && this.eventPeriodType[this.selectedEvent.type] === 'calendarReference' ?
            this.selectedEvent.period.calendarReference.instance : '';

        if (this.saveExceptionEventForm.controls['eventCalendarRef']) {
            this.saveExceptionEventForm.controls['eventCalendarRef'].setValue(calendarRef);
        } else {
            this.saveExceptionEventForm.addControl('eventCalendarRef', new FormControl(calendarRef, Validators.required));
        }
        this.saveExceptionEventForm.removeControl('eventDate');
        this.removeDateRangeFields();
        this.removeWeekNDayFields();
    }

    private switchToWeekNDay() {
        let selectedEventDay = '';
        let selectedEventMonth = '';
        let selectedEventWeek = '';

        if (this.selectedEvent && this.eventPeriodType[this.selectedEvent.type] === 'weekNDay') {
            selectedEventDay = this.selectedEvent.period.weekNDay.dayOfWeek || 0;
            selectedEventMonth = this.selectedEvent.period.weekNDay.month || 0;
            selectedEventWeek = this.selectedEvent.period.weekNDay.weekOfMonth || 0;
        }

        if (this.saveExceptionEventForm.controls['selectedEventDay']) {
            this.saveExceptionEventForm.controls['selectedEventDay'].setValue(selectedEventDay);
            this.saveExceptionEventForm.controls['selectedEventWeek'].setValue(selectedEventWeek);
            this.saveExceptionEventForm.controls['selectedEventMonth'].setValue(selectedEventMonth);
        } else {
            this.saveExceptionEventForm.addControl('selectedEventDay', new FormControl(selectedEventDay, Validators.required));
            this.saveExceptionEventForm.addControl('selectedEventWeek', new FormControl(selectedEventWeek, Validators.required));
            this.saveExceptionEventForm.addControl('selectedEventMonth', new FormControl(selectedEventMonth, Validators.required));
        }
        this.saveExceptionEventForm.removeControl('eventDate');
        this.saveExceptionEventForm.removeControl('eventCalendarRef');
        this.removeDateRangeFields();
    }

    private removeWeekNDayFields() {
        this.saveExceptionEventForm.removeControl('selectedEventDay');
        this.saveExceptionEventForm.removeControl('selectedEventWeek');
        this.saveExceptionEventForm.removeControl('selectedEventMonth');
    }

    private removeDateRangeFields() {
        this.saveExceptionEventForm.removeControl('eventStartDate');
        this.saveExceptionEventForm.removeControl('eventEndDate');
    }

    private isValidPeriod(period) {
        let isValid = true;
        let start = moment(period.start, 'HH:mm:ss');
        let end = moment(period.end, 'HH:mm:ss');

        this.selectedEvent.timeValues.forEach((existingPeriod) => {
            if (this.selectedPeriod && this.selectedPeriod.id === existingPeriod.id) { return; }
            if (moment(existingPeriod.start, 'HH:mm:ss').isBefore(end) && moment(start).isBefore(moment(existingPeriod.end, 'HH:mm:ss'))) {
                isValid = false;
            }
        });
        return isValid;
    }

    private generateDayList() {
        for (let i = 1; i <= 31; i++) {
            this.dayList.push({ label: `${i}`, value: i });
        }
    }

    private isValidDate(dateObj) {
        let hasWildcard = false;
        let invalidDate = false;

        Object.keys(dateObj).forEach(key => {
            if (dateObj[key] === undefined || dateObj[key] === '') {
                invalidDate = true;
            }
            if (dateObj[key] === 0) {
                hasWildcard = true;
            }
        });

        if (invalidDate) {
            return false;
        }

        if (hasWildcard) {
            return true;
        }

        let date = moment(`${dateObj.selectedMonth}/${dateObj.selectedDay}/${dateObj.selectedYear}`, 'MM/DD/YYYY');
        return date.isValid();
    }

    private isValidRangeDate(startDateObj, endDateObj) {

        if (!this.isWildCardPositionValid(startDateObj.selectedDay, endDateObj.selectedDay)) {
            return false;
        }

        if (!this.isWildCardPositionValid(startDateObj.selectedMonth, endDateObj.selectedMonth)) {
            return false;
        }

        if (!this.isWildCardPositionValid(startDateObj.selectedYear, endDateObj.selectedYear)) {
            return false;
        }

        const today = new Date();
        const currentYear = today.getFullYear();

        let startDate = moment(`${startDateObj.selectedMonth || 1}/${startDateObj.selectedDay || 1}/${startDateObj.selectedYear || currentYear}`, 'MM/DD/YYYY');
        let endDate = moment(`${endDateObj.selectedMonth || 2}/${endDateObj.selectedDay ||  2}/${endDateObj.selectedYear || currentYear + 1}`, 'MM/DD/YYYY');

        if (!startDate.isValid() || !endDate.isValid()) {
            return false;
        }

        return startDate.isSameOrBefore(endDate);
    }

    private isWildCardPositionValid(start, end) {
        if ( (start === 0 || end === 0) && start !== end) {
            return false;
        }
        return true;
    }

    private resetDatesErrorMessage() {
        this.showInvalidDateError = {
            singleDay: false,
            rangeStartDate: false,
            rangeEndDate: false
        };
        this.showInvalidRangeError = false;
    }

    private dateHasWildcared(dateObj) {
        let hasWildcard = false;

        Object.keys(dateObj).forEach(key => {
            if (dateObj[key] === 0) {
                hasWildcard = true;
            }
        });
        return hasWildcard;
    }

    private getPeriodSignalType(eventdData) {
		let selectedSignalType = '';
		eventdData.some((eventPeriod) => {
			if (eventPeriod.timeValues.length) {
				selectedSignalType = eventPeriod.timeValues[0].type;
			}
			return eventPeriod.timeValues.length;
		});
		return selectedSignalType;
	}

}
