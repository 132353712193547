<p-table class="cbms-generic-table-style" [columns]="selectedColumns" [value]="auditData" [lazy]="true"
    (onLazyLoad)="loadAuditDataLazy($event)" (onPage)="onPage($event)" [paginator]="true" [rows]="pageSize"
    [totalRecords]="totalRecords" [resizableColumns]="true" columnResizeMode="expand" [scrollable]="true"
    scrollHeight="flex" [lazyLoadOnInit]="false" stateStorage="session" stateKey="audit-session">
    <ng-template pTemplate="caption">
        <div class="caption-wrapper">
            <div class="filter-block">
                <p-multiSelect [options]="cols" [(ngModel)]="selectedColumns" optionLabel="header"
                    (ngModelChange)='selectedColumnsHaveChanged($event)' selectedItemsLabel="{0} columns selected"
                    [style]="{minWidth: '200px'}" placeholder="Choose Columns">
                </p-multiSelect>
            </div>
            <div class="export-btn">
                <button (click)="exportActionLog()" class="p-element p-button-raised p-button-text cbms-button cbms-button__turquoise-bg cbms-margin-left-auto p-button p-component">EXPORT ACTION LOG</button>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field" pResizableColumn
                style="min-width: fit-content; width: {{col.width}}" [ngClass]="col.field === ACTIONS ? 'actions-column' : ''">
                <div class="cbms-disply-flex-row-centered">
                    <span>{{col.header}}</span>
                    <p-sortIcon *ngIf="col.field !== ACTIONS" [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </div>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-auditRow let-columns="columns">
        <tr>
            <td *ngFor="let col of columns" class="ui-resizable-column text-top" style="min-width: {{col.width}}; width: {{col.width}}">
                <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'actionTimestamp'">
                        {{auditRow[col.field] ? (auditRow[col.field] | date:'dd.MM.yyy, HH:mm:ss') : "N/A" }}
                    </ng-container>
                    <ng-container *ngSwitchCase="ACTIONS">
                        <div class="actions-column">
                            <button pButton class="p-button-raised p-button-text cbms-row-button" (click)="showDetailActionDialog(auditRow)"
                                    pTooltip="Read properties" showDelay="1000" hideDelay="500">
                                <mat-icon svgIcon="description"></mat-icon>
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        {{auditRow[col.field]}}
                    </ng-container>
                </ng-container>

            </td>
        </tr>
    </ng-template>
</p-table>
<div class="cbms-spinner-wrapper" *ngIf="isLoading">
    <mat-spinner [diameter]="60" class="cbms-spinner"></mat-spinner>
</div>
